export const getComponentName = ( { commit }, data ) => {
    try{
        let componentName = "";
        switch( data.toLowerCase() ){
            case 'iframe sencillo':
                componentName = 'Iframe'
            break;
            case 'promociones':
                componentName = 'CarouselPromotions'
            break;

            case  'grupo sencillo':
                componentName = 'CarouselDishPackages'
            break;

            case 'slim':
                componentName = 'CarouselPromotionsBanner'
            break

            case 'hero':
                componentName = 'CarouselHeroBanner'
            break;

            case 'titulo simple':
                componentName = 'TitleComponent'
            break;
        }

        return componentName

    }catch( err ){
        commit( 'LOADING_PREVIEW', false )
        commit( 'ERROR_PREVIEW', err.message )
    } finally {
        commit( 'LOADING_PREVIEW', false )

    }
}
export const generateComponent = async ( {commit, dispatch}, payload ) => {
    try{

    console.log( payload)
    let data = []
    const content = payload[1]
    const values = payload[0]

    if( content && Object.keys(content).length > 0 ){
        let dataList = { list : [], title:  content.name }
        
        values.forEach(( element ) => {
            let group = {}

            element.forEach( (el ) => {
                if( el.key == 'type_redirect' && el.value == null  ){
                    group[el.key] = "||"
                } else {
                    group[el.key] = el.value
                }
            })
            dataList['list'].push( group )
        });
        console.log( dataList )
        const componentName = await dispatch('getComponentName',  content.name )
        data.push({ name : componentName , data : dataList  })
    }
        return data
    } catch( err ){
        commit( 'LOADING_PREVIEW', false )
        commit( 'ERROR_PREVIEW', err.message )
    } finally {
        commit( 'LOADING_PREVIEW', false )

    }
}