<template>
	<template v-for="(btn, index) in list" :key="index">
		<Button
			@move-card="moveCard"
			:index="btn"
			:init-selected="index === 0"
		></Button>
	</template>
</template>

<script>
import Button from "@/components/carousel/promotions_carousel/Button.vue";
import { mapGetters, mapState, useStore } from 'vuex'

export default {
	name: "NavButtons",
	components: { Button },
	props: {
		list: Array,
		dataList: [],
		isMobile: Boolean,
	},
	emits: ["moveCard"],
	created() {
		this.$parent.$data.navButtons = this;
	},
	mounted() {
		this.$nextTick(() => this.buttonsToHide());
	},
	data() {
		return {
			isMiddle: false,
			xVal: 0,
			buttons: [],
			// store: useStore()
		};
	},
	computed: {
		size() {
			/**
			 * w-6  => 1 button
			 * w-28 => 2 buttons
			 * w-36 => 3 buttons
			 */
			return this.isMiddle
				? this.showOneButtonFull
					? `w-20`
					: this.showTwoButtons
					? `w-28`
					: `w-36`
				: `w-6`;
		},
		showTwoButtons() {
			return this.selectedIndex === this.secondToLast
				? true
				: this.selectedIndex === this.$parent.indexWithFeatured;
		},
		secondToLast() {
			return this.list.length - 2;
		},
		showOneButtonFull() {
			return this.$parent.indexWithFeatured === this.selectedIndex
				? this.selectedIndex === this.secondToLast
				: false;
		},
		xPos() {
			return `transform: translateX(${this.xVal}px);`;
		},
		selectedIndex() {
			return this.$parent.buttons.selected.index;
		},
	},
	methods: {
		adjustXPos(index) {
			if (this.selectedIndex === index) return;
			const gap = 8;
			const btnWidthMin = 24;
			if (index < this.$parent.indexWithFeatured) {
				this.xVal = 0;
			} else {
				const showButtons = this.buttons.filter(
					(button) =>
						button.$props.index > this.$parent.indexWithFeatured &&
						button.$props.index < index
				);
				const buttonWidths = showButtons.map(() => btnWidthMin);
				const totalWidth = (total, val) => total + val + gap;
				this.xVal = -1 * buttonWidths.reduce(totalWidth, 0);
			}
		},
		moveCard(payload) {
			if(this.isMobile){
            	this.$store.commit('SET_CURRENT_LINK_PROMOTION', this.dataList[payload.index].type_redirect )
			}

			const { index } = payload;
			const length = this.list.length - 1;
			this.isMiddle = index > 0 && index < length;
			//if (this.isMiddle || index === length) this.adjustXPos(index);
			//else if (index === 0) this.buttonsToShow();
			this.$emit("moveCard", payload);
		},
		select(index) {
			this.buttons[index].moveCard();
		},
		deselect(index) {
			this.buttons[index].deselect();
		},
		buttonsToShow() {
			this.buttons.forEach((button) => button.showButton());
		},
		buttonsToHide() {
			const hide = this.buttons.filter(
				(button) =>
					button.$props.index > 0 &&
					button.$props.index <= this.$parent.indexWithFeatured &&
					button.$props.index != this.list.length - 1
			);

			hide.forEach((button) => button.hideButton());
		},
	},
};
</script>

<style scoped>
.anim-width {
	transition: width;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.3s;
}
</style>