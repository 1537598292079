<template>
<div v-if="data" class="iframe-container">
    <iframe :src="data.value" frameborder="0" width="100%"  ></iframe>
</div>
</template>
<script>
import { inject, onMounted, ref } from '@vue/runtime-core'
export default {
    props: [ 'data' ],
    setup(){
        const currentMediaQuery = inject('mq')
        const largeMediaQueries = [ 'md','lg','xl','xxl']
        const height = ref('')
        const getHeight = () => {
            height.value = largeMediaQueries.includes(currentMediaQuery.value) ? 2000 : 8024 
        }
        onMounted( () => {
            getHeight()
        })
        return{ currentMediaQuery, getHeight, height}
    }
}
</script>
<style scoped>

.iframe-container{
    position: absolute; 
    top:0;
    left: 0;
    display: flex;
        height: 100%;
    width: 100%;
}
iframe{
    height: 100%;
    overflow-y: scroll;
}
</style>