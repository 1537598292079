<template>
	<div :style="{ background:`url(${ getImagePerResoluition( details.image_descktop, details.image_mobile, currentMediaQuery) })`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center top' }" class="rounded-2xl w-full h-full bg-no-repeat xs:bg-cover sm:bg-cover relative">
		<div class="cursor-pointer bg-gradient-to-b from-transparent
				to-gray-900 w-full p-5
				text-white float-right 
				xs:bg-gradient-to-b sm:bg-gradient-to-b
				text-left rounded-2xl flex flex-col justify-end xs:text-center xs:bottom-0 xs:w-full sm:text-center sm:bottom-0 sm:w-full h-full" 
		>
			<div class="absolute top-5 right-5"  v-if="details.logo">
				<img :src="`${details.logo}`" :alt="title"/>
			</div>
			<h3
				class="text-white normal-case xs:mb-2 xs:text-center sm:text-center md:text-left lg:text-left xl:text-left 2xl:text-left"
				v-html="details.text_secondary"
				:class="`${title}`"
			></h3>
			<p class="text-white" v-html="details.text_price"></p>
			<a class="btn-red mt-10 xs:mt-5 sm:mt-5  font-semibold xs:m-auto sm:m-auto text-center cursor-pointer xs:mb-0 sm:mb-0  z-10"  href="#"  rel="noreferrer" v-if="details.type_redirect" v-show="details.type_redirect.split('|')[0] == 'I' " @click="setPageNumber(details.type_redirect)">
				{{ details.text_call_to_action }}
			</a>
			<a class="btn-red mt-10 xs:mt-5 sm:mt-5  font-semibold xs:m-auto sm:m-auto text-center cursor-pointer xs:mb-0 sm:mb-0 z-10" :target="checkURLRedirectBlank( details.type_redirect.split('|')[2] )" rel="noreferrer" :href="details.type_redirect.split('|')[2]" v-if="details.type_redirect" v-show="details.type_redirect.split('|')[0] == 'E' ">
				{{ details.text_call_to_action }} 
			</a>
		</div>
	</div>
</template>
<script>
import { inject } from '@vue/runtime-core';
import { computed } from "vue";
import { mapGetters, mapState, useStore } from 'vuex'

export default {
	props: {
		details: {
			type: Object,
			default: () => {
				return {
					type_redirect : "||"
				}
			}
		},
		isMobile: Boolean,
	},
	setup(props) {
		const store = useStore()
		const currentMediaQuery = inject('mq')
		const bgImg = computed(() => {
			return props.details.image_descktop
				? `background-image: url(${props.details.image_descktop});`
				: "";
		});
		const title = computed(() => {
			return props.details.featured ? "normal-case" : "text-2xl";
		});
		const bgColor = () => {
			let randomColor = Math.floor(Math.random() * 16777215).toString(16);
			randomColor =
				randomColor.length < 6 ? `${randomColor}2` : randomColor;
			return `background-color: #${randomColor}`;
		};

		const getImagePerResoluition = ( desktopImage, mobileImage, mediaQuery ) => {
            return (mediaQuery.current == 'xs' ?  encodeURI(mobileImage) : encodeURI(desktopImage)  )
        }

		const setPageNumber = ( type_redirect ) => {
			if(props.isMobile){
				type_redirect = store.getters.getCurrentLinkPromotion
			}

			const type = type_redirect.split('|')[0]
			const pageNumber = type_redirect.split('|')[1]
			const link = type_redirect.split('|')[2]

			const index = link.indexOf('registro/paquete')

			if( type == 'I'){
				if( pageNumber ){
					store.commit( 'SET_PAGE_NUMBER', pageNumber )
					window.scrollTo(0, 0);
				}
			} else {
				if( index >= 0 ){
					location.href = link 
				} else {
					window.open(link);
				}
				
			}
		}
		const checkURLRedirectBlank = ( url )=> {
            const index = url.indexOf('registro/paquete')
            if( index >= 0 ){
                return '_self'
            } else {
                 return '_blank'
            }
        }

		return { getImagePerResoluition, currentMediaQuery, bgImg, bgColor, title, setPageNumber, checkURLRedirectBlank};
	},
};
</script>