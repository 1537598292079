import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Register from '../views/Registro.vue'
import Pago from '../views/Pago.vue'
import Resumen from '../views/Resumen.vue'
import PageNotFound from '../views/PageNotFound.vue'
import PreviewModule from '../views/PreviewModule.vue'
import Search from '../views/Search.vue'
import { ampAnalytics} from './guards'

import  store  from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/#/',
    name: 'PreviewModule',
    component: Home,
  },
  {
    path: '/registro',
    name: 'Register',
    component: Register
  },
  {
    path: '/registro/paquete/:package_code',
    name: 'RegisterCode',
    component: Register
  },
  
  {
    path: '/pago',
    name: 'Pago',
    component: Pago
  },
  {
    path: '/resumen',
    name: 'Resumen',
    component: Resumen
  },
  {
    path: '/404', 
    name: 'PageNotFound',
    component: PageNotFound
  },
  
  {
    path: '/preview-module/preview/', 
    name: 'PreviewModule',
    component: PreviewModule
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'SlugHome',
    component: Home
  },
  {
    path: '/buscador-caneles', 
    name: 'Search',
    component: Search
  },
  
]
console.log( process.env.BASE_URL)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async ( to, from, next ) =>{
  window.scrollTo(0, 0)
  ampAnalytics( to )
  if ( to.name == 'Home'  ) {
    store.commit('SET_PACKAGES_INFORMATION' , null)
    store.commit('SET_SUBSCRIBER_REGISTRATION',  null )
    store.commit('SET_SMS_VALIDATION' , null)
    store.commit('SET_ERROR_SUBSCRIPTION', "" )
    store.commit('SET_SUBSCRIPTION_COMPLETE', false )
  }
  if( store.state.Token.token ){
    next();
  } else {
    await store.dispatch('callPublicToken');
    next();
  }
})

export default router
