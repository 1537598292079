<template>
	<div
		:style="featured"
		:class="isMobile ? 'absolute h-full' : 'anim-flex'"
		class="overflow-hidden flex items-stretch duration-300 ease-in-out card"
	>
		<div :class="`${showCard}`" class="w-full rounded-lg transform">
			<slot name="content"></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "Card",
	props: {
		index: Number,
		isFeatured: Boolean,
		isMobile: Boolean,
	},
	created() {
		this.$parent.addCard(this);
	},
	mounted() {
		this.$nextTick(() => this.checkAutoShow());
	},
	data() {
		return {
			isSelected: false,
			isNext: false,
			isAutoShow: false,
		};
	},
	computed: {
		featured() {
			if (this.isMobile) {
				return "width: 100%";
			} else {
				return this.isFeatured
					? `flex: 0 0 ${this.$parent.$props.cardWidth.max}px;`
					: `flex: 0 0 ${this.$parent.$props.cardWidth.min}px;`;
			}
		},

		showCard() {
			if (this.isMobile) {
				return this.isNext
					? `transition-opacity duration-500 ease-in-out opacity-100`
					: `transition-opacity duration-500 ease-in-out opacity-0`;
			} else {
				return this.isAutoShow
					? `translate-x-0`
					: this.isNext
					? `transition-transform duration-300 delay-200 ease-in-out translate-x-0`
					: `transition-transform duration-300 delay-200 ease-in-out translate-x-0`;
			}
		},
	},
	methods: {
		select() {
			this.$parent.select(this);
			this.isSelected = true;
		},
		deselect() {
			this.isSelected = false;
		},
		moveCard() {
			this.isNext = true;
		},
		autoShow() {
			this.isAutoShow = true;

			setTimeout(() => {
				this.isAutoShow = false;
				this.isNext = true;
			}, 1);
		},
		hideCard() {
			this.isNext = false;
		},
		checkAutoShow() {
			if (this.index < this.$parent.indexWithFeatured) this.autoShow();
		},
	},
};
</script>

<style scoped></style>