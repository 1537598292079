/*eslint-disable */
export const landing = {
    tag : () => {
        cntrUpTag.track('cntrData', '3370b80838250ad2');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '3370b80838250ad2');
    },
    image: `<img src="https://pixel.sitescout.com/up/3370b80838250ad2?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/3370b80838250ad2?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const packageButton = {
    tag : () => {
        cntrUpTag.track('cntrData', '327bc4afab71ec9f');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '327bc4afab71ec9f');
    },
    image: `<img src="https://pixel.sitescout.com/up/327bc4afab71ec9f?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/327bc4afab71ec9f?erp_id=`
}


export const coburtura = {
    tag : () => {
        cntrUpTag.track('cntrData', '0aacb6d2ea4a0b31');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '0aacb6d2ea4a0b31');
    },
    image: `<img src="https://pixel.sitescout.com/up/0aacb6d2ea4a0b31?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/0aacb6d2ea4a0b31?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}


export const checkoutStepOne = {
    tag : () => {
        cntrUpTag.track('cntrData', '27e37dd86f4f47f4');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '27e37dd86f4f47f4');
    },
    image: `<img src="https://pixel.sitescout.com/up/27e37dd86f4f47f4?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/27e37dd86f4f47f4?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}


export const checkoutStepTwo = {
    tag : () => {
        cntrUpTag.track('cntrData', 'a12a1085b5bae6ed');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'a12a1085b5bae6ed');
    },
    image: `<img src="https://pixel.sitescout.com/up/a12a1085b5bae6ed?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/a12a1085b5bae6ed?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}


export const checkoutStepThree = {
    tag : () => {
        cntrUpTag.track('cntrData', '0ba379288ae87d80');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '0ba379288ae87d80');
    },
    image: `<img src="https://pixel.sitescout.com/up/0ba379288ae87d80?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/0ba379288ae87d80?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}


export const checkoutStepFour = {
    tag : () => {
        cntrUpTag.track('cntrData', '70e45e627bf485d0');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '70e45e627bf485d0');
    },
    image: `<img src="https://pixel.sitescout.com/up/70e45e627bf485d0?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/70e45e627bf485d0?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}


export const checkoutStepFive = {
    tag : () => {
        cntrUpTag.track('cntrData', 'b17c56297e98de16');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'b17c56297e98de16');
    },
    image: `<img src="https://pixel.sitescout.com/up/b17c56297e98de16?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/b17c56297e98de16?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const checkoutStepSix = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f34393a814681d08');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f34393a814681d08');
    },
    image: `<img src="https://pixel.sitescout.com/up/f34393a814681d08?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f34393a814681d08?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const pagar = {
    tag : () => {
        cntrUpTag.track('cntrData', '4fac08b46b727154');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', '4fac08b46b727154');
    },
    image: `<img src="https://pixel.sitescout.com/up/4fac08b46b727154?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/4fac08b46b727154?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const success = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f3607b205dd0e6aa');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f3607b205dd0e6aa');
    },
    image: `<img src="https://pixel.sitescout.com/up/f3607b205dd0e6aa?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f3607b205dd0e6aa?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

export const pagoEffectivo = {
    tag : () => {
        cntrUpTag.track('cntrData', 'f2b97f3f1b45fe6b');
    },
    floodlight : () =>{
        cntrUpTag.track('cntrData', 'f2b97f3f1b45fe6b');
    },
    image: `<img src="https://pixel.sitescout.com/up/f2b97f3f1b45fe6b?cntr_revenue=%pcost=!;&cntr_transactionId=%pord=!;&u1=%pu1=!;&u2=%pu2=!;&u3=%pu3=!;&u4=%pu4=!;&u5=%pu5=!;&cntr_url=%p~oref=!?" width="1" height="1" style="position: absolute; left: -150px;" />`,
    postback : `https://pixel.sitescout.com/up/f2b97f3f1b45fe6b?cntr_postbackAuctionId=[POSTBACK_GOES_HERE]`
}

