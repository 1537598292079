export const LOADING_SEARCH = ( state, boolean ) => {
    state.loading =  boolean
}

export const ERROR_SEARCH = ( state, message ) => {
    state.error =  message
}

export const SET_CATEGORIES_CHANNEL = ( state, channels ) => {
    state.channels =  channels
}

export const SET_PACKAGES_CHANNEL = ( state, channels ) => {
    state.packages =  channels
}
export const SET_AUTOCOMPLETE = ( state, result ) =>{
    state.autocompleteResult = result
}