<template>
	<div class=" w-full flex flex-col bg-white py-5 md:py-0">
		<div
			ref="vp"
			class="overflow-hidden container md:w-full md:max-w-full lg:max-w-full xl:max-w-full 2xl:w-full 2xl:max-w-full"
		>
		
			<div
				:style="`${xPos} ${maxHeight}`"
				:class="`${
					isCardsCenter || isCardsFitViewport
						? 'justify-center'
						: 'pl-10'
				}`"
				class="flex gap-4 transition-transform duration-300 ease-in-out"
			>   
				<template v-for="(card, index) in list" :key="index">
					<card
						:index="index"
						:is-featured="( card.title == 1 ? true : false )"
						:is-mobile="isMobile"
					>
						<template v-slot:content>
							<card-layout :details="card"></card-layout>
						</template>
					</card>
				</template>
			</div>
		</div>
		<div
			v-if="buttons.list.length && !isCardsCenter && !isCardsFitViewport"
			class="flex justify-center items-center my-8 w-full  gap-2 "
		>
			<nav-buttons
				:list="buttons.list"
				@move-card="moveCards"
			></nav-buttons>
		</div>
	</div>
</template>

<script>
import Card from "@/components/carousel/promotions_carousel/Card.vue";
import CardLayout from "@/components/carousel/promotions_carousel/CardLayout.vue";
import NavButtons from "@/components/carousel/promotions_carousel/NavButtons.vue";

/**
 *
 *  featured: 588px
 *  normal cards: 283px
 *  gap slides: 16px
 *
 */

export default {
	name: "Carousel",
	components: {
		Card,
		NavButtons,
		CardLayout,
	},
	props: {
		list: Array,
		cardWidth: {
			min: Number,
			max: Number,
		},
		height: Number,
		isMobile: Boolean,
	},
	mounted() {
		this.checkViewport();
		this.init();
		this.$nextTick(() => {
			if (this.cards.list.length > 0) {
				this.cards.list[0].select();
				if (Object.entries(this.buttons.selected).length > 0)
					this.buttons.selected.select();
			}
		});

		window.addEventListener("resize", this.checkViewport);
	},
	data() {
		return {
			cards: {
				selected: Object,
				list: [],
				featuredCount: 0,
				featuredViewportCount: 0,
			},
			xVal: 0,
			buttons: {
				selected: Object,
				list: [],
			},
			navButtons: Object,
			prevIndex: 0,
			viewport: 1440,
			isCardsFitViewport: false,
		};
	},
	computed: {
		xPos() {
			return `transform: translateX(${this.xVal}px);`;
		},
		maxHeight() {
			return `height: ${this.height}rem;`;
		},
		lastIndex() {
			return this.cards.list.length - 1;
		},
		isLastCard() {
			return Object.entries(this.buttons.selected).length > 0
				? this.buttons.selected.index === this.lastIndex
				: false;
		},
		indexThreshold() {
			return Math.floor(this.viewport / this.cardWidth.min);
		},
		indexWithFeatured() {
			const diff = this.indexThreshold - this.cards.featuredCount;
			const { featuredViewportCount } = this.cards;
			if (
				featuredViewportCount > 0 &&
				featuredViewportCount * this.cardWidth.max > this.viewport
			) {
				return this.cards.featuredViewportCount;
			} else return diff > 0 ? diff : this.cards.featuredCount;
		},
		isCardsCenter() {
			return this.indexWithFeatured > this.cards.list.length;
		},
	},
	methods: {
		init() {
			this.cards.list.forEach((item, index) => {
				if (item.$props.isFeatured && index < this.indexThreshold) {
					this.cards.featuredCount++;
					if ((index + 1) * this.cardWidth.max <= this.viewport) {
						this.cards.featuredViewportCount++;
					}
				}
				this.buttons.list.push(index);
			});
			this.$nextTick(() => {
				const cardWidths = this.cards.list.map((card) =>
					card.$props.isFeatured
						? this.cardWidth.max
						: this.cardWidth.min
				);
				const gap = 16;
				const padX = 40;
				const spacer = padX;
				const totalWidth = (total, val) => total + val + gap;
				const total = cardWidths.reduce(totalWidth) + spacer;
				//const offset = this.viewportCardOffset(gap);
				this.isCardsFitViewport = this.viewport > total;
			});
		},
		addCard(card) {
			this.cards.list.push(card);
		},
		select(card) {
			if (Object.entries(this.cards.selected).length > 0)
				this.cards.selected.deselect();

			this.cards.selected = card;
		},
		moveCards(payload) {
			const { index, btn } = payload;

			this.$nextTick(() => {
				if (index === 0) this.showAllCards(false);
				else if (this.isLastCard) this.showAllCards(true);

				if (
					(this.prevIndex + 1 < index &&
						this.prevIndex <= this.indexWithFeatured) ||
					(this.prevIndex > this.indexWithFeatured &&
						index > this.prevIndex + 1)
				) {
					this.showAllCardsBelowSelected(index);
				}
			});

			if (
				Object.entries(this.buttons.selected).length > 0 &&
				this.buttons.selected !== btn
			) {
				this.prevIndex = this.buttons.selected.index;

				if (
					this.prevIndex > index &&
					this.prevIndex >= this.indexWithFeatured
				) {
					this.cards.list[this.prevIndex].hideCard();
				}

				this.buttons.selected.deselect();
			}

			if (index < this.indexWithFeatured) {
				if (
					index > 0 &&
					this.indexWithFeatured === this.indexThreshold &&
					this.lastIndex
				) {
					this.xVal = -(this.cardWidth.min * 0.5);
				} else this.xVal = 0;
			} else {
				const showCards = this.cards.list.filter(
					(card) =>
						card.$props.index >= this.indexWithFeatured &&
						card.$props.index <= index
				);
				const cardWidths = showCards.map((card) =>
					card.$props.isFeatured
						? this.cardWidth.max
						: this.cardWidth.min
				);
				const gap = 16;
				const padX = 40;

				const spacer =
					this.lastIndex === index ? padX * 2 + gap + padX : padX;
				const totalWidth = (total, val) => total + val + gap;
				const total = cardWidths.reduce(totalWidth) + spacer;
				const offset = this.viewportCardOffset(gap);
				this.xVal = offset + -total;
			}

			this.cards.list[index].moveCard();
			this.buttons.selected = btn;
			this.$nextTick(() => {
				if (index >= this.indexWithFeatured)
					this.hideAllCardsAboveSelected(index);
			});
		},
		showAllCards(isShow) {
			this.cards.list.forEach((card) => {
				if (isShow) {
					card.autoShow();
				} else {
					if (card.index <= this.indexWithFeatured) {
						card.autoShow();
					} else card.hideCard();
				}
			});
		},
		showAllCardsBelowSelected(index) {
			this.cards.list.forEach((card) => {
				if (card.index <= index) {
					card.autoShow();
				} else {
					card.hideCard();
				}
			});
		},
		hideAllCardsAboveSelected(index) {
			this.cards.list.forEach((card) => {
				if (card.index > index) {
					card.hideCard();
				}
			});
		},
		checkViewport() {
			this.viewport = window.visualViewport.width;
			if (Object.entries(this.navButtons).length > 0) {
				this.$nextTick(() => this.navButtons.buttonsToShow());
				this.$nextTick(() => this.navButtons.buttonsToHide());
				this.$nextTick(() => this.navButtons.buttons[0].moveCard());
			}
		},
		viewportCardOffset(gap) {
			const showingCards = this.cards.list.filter(
				(card) => card.$props.index < this.indexWithFeatured
			);

			const cardWidths = showingCards.map((card) =>
				card.$props.isFeatured ? this.cardWidth.max : this.cardWidth.min
			);
			const totalWidth = (total, val) => total + val + gap;
			const total = cardWidths.length ? cardWidths.reduce(totalWidth) : 0;

			return this.viewport > total ? this.viewport - total : 0;
		},
	},
};
</script>

<style>
.anim-flex {
	transition: flex;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.3s;
}
</style>