export const SET_SMS_VALIDATION = ( state, payload ) => {
    state.sms_validation = payload 
}
export const SET_CUSTOMER_VALIDATION = ( state, payload ) => {
    state.customer_validation = payload 
}
export const SET_LOADING = ( state, boolean ) => {
    state.loading =  boolean
}
export const SET_PRODUCT_REGISTRATION = ( state, payload ) => {
    state.product_registration =  payload
}
export const SET_SUBSCRIBER_REGISTRATION = ( state, payload ) => {
    if ( payload ){
        state.subscriber_registration = payload 
        localStorage.setItem('SET_SUBSCRIBER_REGISTRATION', JSON.stringify(payload) )
    } else {
        localStorage.removeItem('SET_SUBSCRIBER_REGISTRATION');
    }
}
export const SET_DIRECTION_REGISTRATION = ( state, payload ) => {
    state.direction_registration =  payload
}
export const SET_GEO_ADDRESS = ( state, payload ) =>{
    state.geo_address =  payload
}
export const SET_REGISTER_SIGNATURE = ( state, payload ) =>{
    state.register_signature =  payload
}
export const SET_GEO_POSTAL =  ( state, payload ) => {
    state.geo_postal_code =  payload
}
export const SET_ERROR_SUBSCRIPTION = ( state, message ) => {
    state.error =  message
}
export const SET_PAGE_DATA = ( state, payload ) => {
    state.page =  payload
}
export const SET_SMS = ( state, payload ) => {
    state.sms =  payload
}
export const SET_REGISTRATION_METHOD = ( state, payload ) =>{
    state.register_method =  payload
}
export const SET_SHIPPING_INFORMATION = ( state, payload ) => {
    state.shipping_information =  payload
}
export const SET_TELMEX_NUMBER = ( state, payload ) => {
    state.telmex_number =  payload
}
export const SET_BARCODE = ( state, payload ) => {
    state.barcode =  payload
}
export const SET_BARCODE_REFERENCE = ( state, payload ) => {
    state.barcode_sms_reference = payload
}
export const SET_SUBSCRIPTION_COMPLETE = ( state, boolean ) => {
    state.subscriber_complete =  boolean
}
export const SET_GOOGLE_ADDRESS = ( state, data ) => {
    state.googleAddress =  data
}
export const SET_USER_DIRECTION = ( state, data) =>{
    state.user_registration = data
}
export const SET_EFFECTIVO_PRICE = ( state, data ) =>{
    state.effectivo_price = data

}
export const SET_PAYMENT_METHOD = ( state, data) =>{
    state.payment_method = data 
}
export const SET_SUSCRIPTION_EFECTIVE = ( state, data ) =>{
    state.suscripcion_efectivo = data

}


export const SET_Tran_Enviada_ViComm = ( state, payload ) => {
    state.tranEnviadaVicomm =  payload
}


export const SET_Tran_Enviada_ViComm2 = ( state, payload ) => {
    state.tranEnviadaVicomm2 =  payload
}

export const SET_ID_PAYPAL = ( state, payload ) => {
    state.id_paypal = payload 
}


export const SET_SUSC_EFECTIVO = ( state, payload ) => {
    state.susc_efectivo = payload 
}


export const SET_Order_Id = ( state, payload ) => {
    state.tranIdPaypal =  payload
}

export const SET_Tran_paypal = ( state, payload ) => {
    state.tranPaypal =  payload
}

export const SET_CHECK_HD = ( state, check ) => {
    state.checkHD =  check
}

export const SET_STEP_REGISTER = ( state, step ) => {
    state.stepRegister =  step
}





