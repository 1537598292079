<template>
    <h2> <Skeletor width="50%"/> </h2>
    <div class="flex justify-center align-middle items-center">
        <Skeletor width="20%" height="400" class="mx-2" v-for="index in 6" :key="index"/>
    </div>
</template>
<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components:{
        Skeletor,
    }
}
</script>