import axios from 'axios'
import {endpoints} from '@/api/endpoints.js';
import { baseURL } from '../../api/environment';
 
export const getAutocomplete = async ( {commit, rootState }, value ) => {

    try{
        commit( 'LOADING_SEARCH', true )
        const endpoint = endpoints.search.getAutocomplete.endpoint+`${value}`
        const token    = await rootState.Token.token.dataObj
        const { data }  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : baseURL,
            headers: {
                'Authorization': token
            },
        })
        if( data.res ) {
            commit( 'SET_AUTOCOMPLETE' , data.dataObj)
        }
    } catch( err ){
        commit( 'ERROR_TOKEN', err.message )
    } finally{
        commit( 'LOADING_SEARCH', false )
    }
}

export const callPackagesOfChannels = async ( { commit, rootState }, params ) => {
    try{
        const endpoint = endpoints.search.getPackagesChannel.endpoint+params+'/NUMBER_DESC'
        const token    = await rootState.Token.token.dataObj

        commit( 'LOADING_SEARCH', true )
    
        const { data }  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : baseURL,
            headers: {
                'Authorization': token
            },
        })

        commit('SET_PACKAGES_CHANNEL', data )
    } catch( err ){
        commit( 'ERROR_TOKEN', err.message )
    }finally{
        commit( 'LOADING_SEARCH', false )
    }
}


export const callGetCategoriesChannel = async ( {commit,  rootState} ) => {
    try{
        const endpoint = endpoints.search.getCategoriesChannel.endpoint
        const token    = await rootState.Token.token.dataObj
        commit( 'LOADING_SEARCH', true )

        const {data }  = await axios.request({
            url:`${endpoint}`,
            method:'get',
            baseURL : baseURL,
            headers: {
                'Authorization': token
            },
        })
        if( data.res ) {
            commit( 'SET_CATEGORIES_CHANNEL' , data.dataObj)
        }
    } catch( err ){
        commit( 'LOADING_SEARCH', false )
        commit( 'ERROR_TOKEN', err.message )
    }
}