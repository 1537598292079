<template>
  <div class="home" :style="mobileBodyHeight" @click="onClearResult()">
    <div ref="bodyRef_" class="mx-auto my-7 md:mt-0 xs:pb-24 sm:pb-24 md:px-0 xs:my-0 xs:mx-0 md:mx-0 md:container 2xl:container xl:container lg:container xs:w-full sm:w-full xl:p-10 relative xs:flex sm:flex xs:flex-col sm:flex-col" >
        <div>
            <div class="w-full lg:rounded-2xl  h-64 flex justify-center flex-col relative search-banner-container md:rounded-none xs:w-full xs:rounded-none sm:w-full md:w-full sm:rounded-none " :style="{ backgroundImage : `url(${stylesSearch.bgimage})`}">
              <div class="heading_separator"></div>
              <h1 class="text-white mt-2"> Encuentra TU PAQUETE IDEAL </h1>
            </div>
            <div class="relative w-min m-auto -top-8 xs:w-full sm:w-full xs:px-7 sm:px-7">
                <input type="text" v-model="search" placeholder="Escribe el canal que estás buscando..." @keyup="onSearch" class="pl-8 py-5 search_input xs:w-full text-18 font-medium rounded-5xl bg-gray-200 text-gray-500" >
                <div class="w-full bg-white  absolute left-0  mt-2 h-80 overflow-y-auto rounded-md z-20" v-if="Object.keys(searchResult).length != 0">
                  <div class="hover:bg-red hover:text-white p-2 cursor-pointer flex justify-between" v-for="(result, index) in searchResult" :key="index" @mousedown.prevent @click="onSelectResult( result )">  
                    <span>{{index}}  </span>
                  </div>
                </div>
                <button @click="onSearch" class="btn-red btn-search absolute font-semibold xs:m-auto sm:m-auto text-center cursor-pointer xs:mb-0 sm:mb-0 z-10 -right-0 xs:right-7 sm:right-7"><img src="@/assets/images/icon-search2.svg" class="xs:m-auto sm:m-auto"/></button>
            </div>
        </div>
        <div class="inner_container xs:w-full sm:w-full md:w-full" v-if="isLoading" >
            <div class="flex justify-center">
               <Skeletor width="300px" class="mr-3 my-10 h-10" />
            </div>
             <div class="flex justify-center">
               <Skeletor width="250px" class="mr-3 my-10 h-10" v-for="index in 8" :key="index"/>
            </div>
            <div class="flex justify-center">
               <Skeletor  class="mr-3  h-20 w-20" v-for="index in 8" :key="index"/>
            </div>
             <div class="flex justify-start">
                <Skeletor  class="mr-3 5-10 h-5 w-3/12 mt-10 bg-red" />
                <Skeletor  class="mr-3 5-10 h-5 w-4/12 mt-10"/>
            </div>
            <div class="flex justify-start mt-10 ">
                <div class="relative w-3/12 flex flex-col mx-4 " v-for="index in 4" :key="index">
                  <Skeletor class="bg-red absolute -top-0 w-full p-2 rounded-br-2xl rounded-bl-2xl"/>
                  <Skeletor class="px-4 py-5 flex flex-col  items-center justify-center rounded-3xl " height="300px" />
                </div>
            </div>
        </div>
        <div class="inner_container xs:w-full sm:w-full md:w-full" v-else>
          <div v-show="categories.length !=0">
              <h2 class="font-bold mt-2">NuestrAS CATEGORÍAS</h2>
              <carousel :items-to-show="5" class="mt-3 carouselCategories"  
                        v-model="categoryCarouselIndex" :wrap-around="true" > 
                  <slide v-for="(cat, index) in categories" :key="index" class="px-2" :wrap-around="true">
                    <button class="bg-gray-100 rounded-full p-5 font-medium w-full text-base" 
                      :class="{ 'bg-red text-white' : selectedCategoryIndex == index }" 
                      @click="onSelectCategory( index ) ">
                      {{ cat.name }}
                    </button>
                  </slide>
                <template #addons>
                   <navigation class="xs:hidden sm:hidden md:hidden"/>
                </template>
              </carousel>
          </div>
          <div class="flex logos_container items-center justify-center m-auto align-middle flex-wrap lg:w-full lg:max-w-max">
              <div class="relative channel-icon" v-for="(channel, index) in channels" :key="index">
                 <a href="#" class="h-5 w-5 absolute bg-gray-100 top-2 right-2 p-1 rounded-full z-10" @click.prevent="onRemoveChannel( channel, index, channels )" :class="{ 'bg-red' : selectedChannel.includes(channel.name)}" v-if="selectedChannel.includes(channel.name)">
                  <img src="@/assets/images/icon-close_white.svg" alt="">
                </a>
                <div class="block bg-gray-100 rounded-full h-24 w-24 m-2 cursor-pointer flex flex-col align-middle justify-center relative"  @click.prevent="onSelectChannel(channel)" :class="{ 'bg-red_search' : selectedChannel.includes(channel.name) } ">
                  <div class="p-2">
                    <img :src="channel.logo" :alt="channel.name">
                  </div>
                </div>
              </div>
          </div>
          <div class="mt-5 flex justify-between xs:flex xs:flex-col xs:px-6 sm:flex sm:flex-col sm:px-6 xs:text-center sm:text-center md:flex md:flex-col md:px-6 md:text-center" v-if="packageList.length != 0 ">
            <p class="text-2xl font-semibold"> <span class="text-red">  {{packageList.length}} paquetes </span> incluyen el canal que estás buscando</p>
            <div class="xs:flex xs:justify-end xs:mt-6 sm:flex sm:justify-end sm:mt-6 md:flex md:justify-end md:mt-6 md:flex md:justify-end md:mt-6">
              <button class="text-red text-14 w-36 flex justify-end font-medium" @click="showOrder = !showOrder "> 
                  Ordenar por 
                  <img src="@/assets/images/icon-arrow.png" class="ml-4"/>
              </button>
              <div class="relative " v-show="showOrder">
                  <ul class=" order-container" >
                    <li><a href="#" @click.prevent="setOrderPackage('priceAsc')" :class="{'text-red' : orderStatus == 'priceAsc'} " class="text-sm text-gray-500">Precio (menor a mayor) </a> </li>
                    <li><a href="#" @click.prevent="setOrderPackage('priceDesc')" :class="{'text-red' : orderStatus == 'priceDesc'} " class="text-sm text-gray-500">Precio (mayor a menor) </a> </li>
                    <li><a href="#" @click.prevent="setOrderPackage('nameAsc')" :class="{'text-red' : orderStatus == 'nameAsc'} " class="text-sm text-gray-500">Nombre (mayor a mayor) </a> </li>
                    <li><a href="#" @click.prevent="setOrderPackage('nameDesc')" :class="{'text-red' : orderStatus == 'nameDesc'} " class="text-sm text-gray-500">Nombre (mayor a menor) </a> </li>
                  </ul>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-4 gap-4 mt-5 xs:flex xs:flex-col xs:px-6 sm:flex sm:flex-col sm:px-6 md:flex md:flex-wrap md:px-6">
            <div v-for="item in packageList" :key="item.erp_id" class="rounded-3xl search_card m-1 shadow-lg w-full bg-gray-250"> 
              <div class="flex flex-col relative">
                <div class="bg-red absolute -top-0 w-full p-2 rounded-tr-2xl rounded-tl-2xl"></div>  
                <div class="px-4 py-5 flex flex-col  items-center justify-center">
                  <h2 class="text-3xl m-0 font-semibold mt-3 mb-2">{{item.package.name}}</h2>
                  <h3 class="text-4xl text-red mb-0">${{item.package.price}}<span class="text-2xl"> /mes </span> </h3>
                  <span class="text-14">{{item.serialize.num_can }}</span>
                  <div class="separator"></div>
                  <a href="" class="text-red text-18 underline mb-2"  @click.prevent="isShow = true; isChannels = true; productData = item">Conocer más</a>
                  <button class="btn-red text-white py-5 mt-2 rounded-5xl font-medium px-8" @click="onSubmit(item)">Contratar</button>
                </div>
              </div>
            </div>
          </div>
          <div class="text-base flex justify-center mt-10 items-center align-middle" v-if="false">
            <button class="text-red text-2xl"> &lt;  </button>
                <a href="#" class="text-base mx-4" v-for="index in 10" :key="index"> {{index}} </a>
            <button class="text-red text-2xl"> &gt; </button>
          </div>
        </div>
          <div v-html="tagImage"> </div>
          <transition name="fade"> 
              <ModalMap v-if="isShowMap" :onShow="isShowMap" @onClose="onCloseMap"  @onSubmit="onSubmit" :productData="productData" :ref="data.title"/>
          </transition>
          <ModalPersonalize v-if="isShow" :isChannels="isChannels" @onClose="onClose" @setChannels="setChannels" :productData="productData" @onSubmit="onSubmit" />
    </div>
  </div>
</template>
<style scoped>
  .order-container{
  @apply absolute -right-1 top-4 z-10 rounded-large border-red border-2 p-5 bg-gray-250 w-max leading-8 font-medium;
    box-shadow: 0px 23px 30px rgba(0, 0, 0, 0.3)
  }
  .order-container li {
    @apply hover:text-red  cursor-pointer;
  }
  .search-banner-container {
    @apply m-auto;
    max-width: 1364px;
  }
  .logos_container {
    max-width: 1056px;
    margin-top:2.5rem;
  }
  .block {
    width:72px;
    height: 72px;
  }
  .bg-red_search {
    background:rgba(214, 33, 46, 0.5)
  }
  .btn-search {
    @apply md:min-w-min lg:min-w-min xl:min-w-min 2xl:min-w-min;
    height:67px;
  }
  .inner_container {
    max-width: 1192px;
    @apply m-auto
  }
  .separator {
    border-bottom: 1px solid rgba(35, 11, 52, 0.35);
    height:1px;
    @apply w-full my-5
  }
  .search_input {
    box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .grid h2 {
    text-transform:  none !important;
  }
  :deep(.carousel__next){
    right: -40px;
    @apply w-16 h-16 bg-black text-26
  }
   :deep(.carousel__prev){
    left: -40px;
    @apply w-16 h-16 bg-black text-26
  }

  :deep(.carousel__prev--in-active),
  :deep(.carousel__next--in-active){
    @apply w-16 h-16 bg-gray-400 text-26
  }
  @media screen and (min-width:1280px){
    .logos_container channel-icon:nth-child(13) {
      margin-left:176px;      
    }
    .logos_container channel-icon:nth-child(20) {
      margin-right:176px;      
    }
    .logos_container channel-icon:nth-child(21) {
      margin-left:352px;      
    }
    .logos_container channel-icon:nth-child(24) {
      margin-right:352px;      
    }
  }  
  @media screen and (min-width:728px) and (max-width:1023px) {
    .search_card {
      flex:1 1 45%;
    }
  }
  @media screen and (min-width:768px) {
    .search_input {
      width:575px;
    }
  }
  @media screen and (max-width:767px){
    .btn-search {
      min-width:68px;
    }
    .search_input {
      height:67px;
      font-size:13px
    }
    .carouselCategories .carousel__slide {
      width:max-content !important
    }
  }
</style>
<style>
  @media screen and (max-width:767px) {
    .carouselCategories .carousel__viewport {
      border-radius:0px !important
    }
  }
  @media screen and (min-width:728px) and (max-width:1023px) {
    .carouselCategories .carousel__slide {
      width:max-content !important
    }
  }
</style>
<script>
// @ is an alias to /src
import 'vue3-carousel/dist/carousel.css';

import { Carousel, Slide, Navigation } from 'vue3-carousel';

import { useStore, mapGetters} from 'vuex'
import { ref } from '@vue/reactivity'
import { computed, onMounted, watch } from '@vue/runtime-core';
import { packageButton, coburtura } from '@/pixel/pixel.js'
import { useRouter } from 'vue-router';
import { Skeletor } from 'vue-skeletor';
import ModalPersonalize from '@/components/modals/ModalPersonalize'
import ModalMap from '@/components/modals/ModalMap.vue'

export default {
  name: 'Search',
  components: {
    Carousel,
    Slide,
    Navigation,
    Skeletor,
    ModalPersonalize,
    ModalMap

  },
   computed:{
      ...mapGetters({
          logos: 'getLogosCategories',
          mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
      })
    },
  setup(){
      const store = useStore(),
       router = useRouter(),
       categoryCarouselIndex = ref(0),
       tagImage = ref(''),
       search = ref(''),
       selectedCategoryIndex = ref(0),
       selectedChannel = ref([]),
       channels = ref([]),
       orderStatus = ref(''),
       isShowMap = ref(false),
       isShow =  ref( false ),
       isChannels = ref( false ),
       packageList = ref([]),
       productData = ref([]),
       collectionChannel = ref([]),
       modelCarousel = ref(0),
       showOrder = ref( false),
       stylesSearch = ref({
          bgimage  : require('@/assets/images/banners/search-backround.png')
       }),
       categories = computed(() => {
        return store.state.Search.channels
      }),
        isLoading = computed(() => {
          return store.state.Search.loading
      }),
      searchResult =  computed(()=> {
        const Autocomplete = store.state.Search.autocompleteResult 
        let data  = {}
          Autocomplete.forEach( (key) =>{
            if( !data[key.canal]) {
              data[key.canal] =  { packages :[ key.clave ], categories: [], name: key.canal, categories_name : key.categorias[0] } 
              data[key.canal].categories.push({
                    logo : key.url,
                    name : key.categorias[0]
                  })
            } else {
              if( data[key.canal].packages.indexOf( key.clave ) == -1 ){
                 data[key.canal].packages.push( key.clave  )
               }
              const find =  data[key.canal].categories.find( ({name }) => {
                return name == key.categorias[0]
              })
              if( !find ){
                data[key.canal].categories.push( { logo : key.url, name : key.categorias } )
              }
            }
          })
          return data 
      })


      const onSelectCategory = async ( index ) =>{
        selectedCategoryIndex.value = index
         await setDefaultCategories()

      }

      const setOrderPackage = ( status ) => {
        orderStatus.value = status
      }

      const onSelectChannel = async ( channel ) =>{
        showOrder.value = false


        if( selectedChannel.value.indexOf(channel.name) == -1) {
          
          selectedChannel.value.push(channel.name)

          const params  = encodeURIComponent(`[${selectedChannel.value.map( name => `"${name}"`).join(',')}]`)



          await store.dispatch('callPackagesOfChannels', params )
          packageList.value =  await store.getters.getSearchPackagesChannel
          console.log(packageList.value)

          setOrderPackageList()
          
        }
      }
    
      const onSelectResult = ( result ) => {
        onClearResult()
        showOrder.value = false
       
        const cat = categories.value
        const index =  cat.findIndex( ({name}) => { return  name == result.categories_name } )

        categoryCarouselIndex.value =  selectedCategoryIndex.value = index
        onSelectCategory( selectedCategoryIndex.value )

        const channel = cat[index].channels.find( value =>  value.name == result.name)

        onSelectChannel( channel )
        store.commit( 'SET_CATEGORIES_CHANNEL' , cat )
      }

      const onSearch = () => {
        showOrder.value = false
        if( search.value.length >=3 ){
          store.dispatch('getAutocomplete', search.value ) 
        }
      }

      const onClearResult = () =>{
        search.value = ''
        store.commit('SET_AUTOCOMPLETE', [])
      }

      const setDefaultCategories = async ( ) => {

        channels.value = await categories.value[selectedCategoryIndex.value].channels
        return channels.value[0].packages
      }
 
      watch( categories, () => {
        setDefaultCategories()
      })

      watch( orderStatus, () => {
        setOrderPackageList()
      })

      const setOrderPackageList = () =>{
          showOrder.value = false
          if( orderStatus.value == 'priceAsc'){
            packageList.value =  packageList.value.sort( (a,b) => {
              return a.package.price - b.package.price 
            })
          }
           if( orderStatus.value == 'priceDesc'){
            packageList.value =  packageList.value.sort( (a,b) => {
              return b.package.price - a.package.price 
            })
          }

          if( orderStatus.value == 'nameAsc'){
            packageList.value =  packageList.value.sort( (a,b) => {
               return a.package.name.localeCompare(b.package.name);
            })
          }
          if( orderStatus.value == 'nameDesc'){
            packageList.value =  packageList.value.sort( (a,b) => {
              return a.package.name.localeCompare(b.package.name);
            })
            packageList.value = packageList.value.reverse()
          }
      }


      const initialLoad = async () =>{
      
        await store.dispatch('callGetCategoriesChannel')
        await setDefaultCategories()
        store.commit('LOADING_SEARCH', false)

      }

      const onSubmit =( product ) =>{
            const scrollPos = document.documentElement.scrollTop
            showOrder.value = false
            store.commit('SET_SCROLL_POSITION', scrollPos)
            if( product.serialize.check_cover ){

                coburtura.tag()
                coburtura.floodlight()
                tagImage.value = coburtura.image

                if ( product.validate ){
                    setDatalayerAddtoCart( product )
                    store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                    router.push('/registro')
                } else {
                    productData.value = product
                    isShowMap.value = true
                }
            } else {
                setDatalayerAddtoCart( product )
                store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                router.push('/registro')
            }      
        }

        const setDatalayerAddtoCart = ( product ) => {
            /* eslint-disable-next-line */
            dataLayer.push({
                'event': 'add_to_cart',
                'ecommerce': {
                    'currencyCode': 'MXN',
                    'add': {                                
                        'products': [{            
                            quantity: 1,
                            name : product.package.name,
                            price: product.package.price,
                            id : product.package.erp_id,
                        }]
                    }
                }
            });

            packageButton.tag()
            tagImage.value = packageButton.image
        }

        const onCloseMap= ( ) => {
            isShowMap.value = false
            store.commit('SET_SCROLLABLE', true)
        }

        const onClose = () => {
            isShow.value = false    
            store.commit('SET_LOGOS', [])
            store.commit('SET_SCROLLABLE',true)
        }
        const setChannels = ( data ) => {
            isChannels.value = data
        }

        const onRemoveChannel =  async ( channel ) =>{

          const removedChannel = selectedChannel.value.filter( ( value ) => {  return value != channel.name  })
          const filter = collectionChannel.value.filter( (value) => { return value.name != channel.name })
          collectionChannel.value = filter
          selectedChannel.value = removedChannel

        if( removedChannel.length == 0 ){
            packageList.value = []
          } else {
              const params  = encodeURIComponent(`[${selectedChannel.value.map( name => `"${name}"`).join(',')}]`)

              await store.dispatch('callPackagesOfChannels', params )
              packageList.value =  await store.getters.getSearchPackagesChannel

              setOrderPackageList()
          }
        }

      onMounted(() =>{
        categoryCarouselIndex.value = 0
       initialLoad()
      })

      return {
        showOrder,
        categories,
        channels,
        packageList,
        search,
        searchResult,
        isShowMap,
        orderStatus,
        isShow,
        isChannels,
        isLoading,
        tagImage,
        stylesSearch,
        collectionChannel,
        productData,
        modelCarousel,
        selectedCategoryIndex,
        categoryCarouselIndex,
        onSelectCategory,
        onSelectResult,
        onClearResult,
        onSubmit,
        onSearch,
        onCloseMap,
        setOrderPackage,
        selectedChannel,
        onClose,
        setChannels,
        onSelectChannel,
        onRemoveChannel
      }
  }
}
</script>