<template>
  <div class="home" :style="mobileBodyHeight"> 
    <Loading :active="loading"
      :can-cancel="true"
      :loader="'dots'"
      :color="'#D6212E'"
      :height="130"
      :width="130"
      :opacity="0.3"
      :is-full-page="true" 
      > </Loading>
    <Header></Header>
    <div class="home-component">
    <component
        v-for="(component,i) in componentData"
        :key="i"
        :is="component.name"
        :data="component.data"
      />
    </div>

    <!-- <div class="fixed bottom-20 right-4 w-96 h-64 shadow-lg" v-if="abierto">
   
   
      <button v-on:click="cerrar()" style="float: right;">
 cerrar x
</button>

   <iframe  
      src="https://d2vl86q2d1cjyw.cloudfront.net/" 
      width="100%" 
      height="100%" 
      frameborder="0"
      allowfullscreen>
    </iframe> -->
<!--     
    <div  v-if="abierto" class="fixed bottom-20 right-4 w-96 h-64 shadow-lg" data-v-b97fa7f2="" style="position: fixed; bottom: 300px; z-index: 9999;">
        <button v-on:click="cerrar()" data-v-b97fa7f2="" style="float: right;"> cerrar x </button>
        <iframe src="https://d2vl86q2d1cjyw.cloudfront.net/" frameborder="0" allowfullscreen="" data-v-b97fa7f2=""
            style="max-width:100%; height: 500px; border-radius: 15px; background-color: #52727f; padding: 5px; box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;">
    </iframe>
</div> -->
  
<!-- <div  v-on:click="cerrar()" v-if="abierto"  class="fixed bottom-20 right-4 w-96 h-64 shadow-lg mainVentana" data-v-b97fa7f2="">
    <button   data-v-b97fa7f2="" class="btnCerrar"> cerrar x </button>
    
    <iframe  class="StyleBot" src="
    https://d2vl86q2d1cjyw.cloudfront.net/"
    width="100%" height="100%" frameborder="0" allowfullscreen="" data-v-b97fa7f2="" top:-100px="">
    </iframe>
</div> -->

<!-- <button v-if="!abierto"  v-on:click="abrir()" class="bg-red hover:bg-red text-white font-bold py-2 px-4 rounded-full shadow-lg fixed bottom-20 right-10">
  +
</button> -->



<div>
  <button id="toggleButton" v-on:click="abrir()">
    <div>
       <img  src="../assets/images/logos/avatar.png" alt="Descripción de la imagen" class="fixed bottom-20 scale-in">
  <div class="help-div">
                            <p>¿Necesitas ayuda?</p>
                        </div>



    </div>
  </button>
   

  </div>

  <div id="backOverlay" class="fixed inset-0 z-50 hidden back-over"></div>

  <div id="cardContainer"
    class="fixed top-0 right-0 h-full w-0 overflow-hidden bg-opacity-75 backdrop-blur-lg shadow-lg transition-all duration-300 cardChat">
 
 
    <button id="closeButton" v-on:click="cerrar()"  class="text-white absolute top-3 right-6 z-30">
        <svg class="w-8 h-8" fill="none" stroke="black" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </button>
 
 
    <div class="w-full h-full px-1 pb-8 pt-6 relative">
        <div class="w-full h-full flex flex-col gap-y-1 items-center">
          <!-- stage viejo-->
            <!-- <iframe src="https://d2vl86q2d1cjyw.cloudfront.net/" class="w-full flex items-center" style="height: 100vh;" frameborder="0" allowfullscreen></iframe> -->
          
          
                    <!-- stage nuevo-->
            <!-- <iframe id="myiFrame" data-src="https://d2vl86q2d1cjyw.cloudfront.net/" class="w-full flex items-center" style="height: 100vh;" frameborder="0" allowfullscreen></iframe> -->
          


          <!-- prod viejo-->
            <!-- <iframe src="https://iris-asistente-prod.dish.com.mx/" class="w-full flex items-center" style="height: 100vh;" frameborder="0" allowfullscreen></iframe> -->
          

            <!-- prod nuevo-->
            <iframe id="myiFrame" data-src="https://iris-asistente-prod.dish.com.mx/" class="w-full flex items-center" style="height: 100vh;" frameborder="0" allowfullscreen></iframe>
        </div>
    </div>
  </div>



<!-- 
<div v-if="abierto" class="fixed bottom-20 right-4 w-96 h-64 shadow-lg" data-v-b97fa7f2="" style="position: fixed; bottom: 300px; z-index: 9999;"><button v-on:click="cerrar()" data-v-b97fa7f2="" style="float: right;"> cerrar x </button><iframe src="
https://d2vl86q2d1cjyw.cloudfront.net/"
width="100%" height="100%" frameborder="0" allowfullscreen="" data-v-b97fa7f2="" style="max-width:100%; height: 500px; border-radius: 15px; background-color: #52727f; padding: 5px; box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;" top:-100px="">
</iframe></div>


<button v-if="!abierto"  v-on:click="abrir()" class="bg-red hover:bg-red text-white font-bold py-2 px-4 rounded-full shadow-lg fixed bottom-20 right-10">
  +
</button> -->
          
    <Footer></Footer> 
  </div>
</template>
<script>





// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Iframe from '@/components/Iframe.vue'
import CarouselHeroBanner from '@/components/carousel/hero-banner.vue'
import CarouselPromotions from '@/components/carousel/promotions-banner.vue'
import CarouselDishPackages from '@/components/carousel/product-packages.vue'
import CarouselPromotionsBanner from '@/components/carousel/promotions-slim.vue'
import TitleComponent from '@/components/Title.vue'
import SearchComponent from '@/components/Search.vue'
import Loading from 'vue-loading-overlay';
import { mapGetters, mapState, useStore } from 'vuex'
import { watch, computed } from 'vue';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useRoute, useRouter } from 'vue-router';








export default {
  name: 'Home',
  data(){
    return {
      toggle: false,
      abierto: false,
      switchFormData : {
        label : '',
        checked: false ,
       
      }
    }
  },

  methods:{
    abrir: async function () {
          //  alert('Hola 2' )
        //console.log(this.abierto)
       this.abierto=true;

       var iframe = $("#myiFrame");
       iframe.attr("src", iframe.data("src")); 

       const cardContainer = document.getElementById('cardContainer');
       const backOverlay = document.getElementById('backOverlay');
  
       const maxWidth = window.innerWidth <= 425 ? '100%' : '425px';
    const isCardOpen = cardContainer.style.width === maxWidth;
    cardContainer.style.width = isCardOpen ? '0' : maxWidth;
    backOverlay.classList.toggle('hidden', isCardOpen);



    },
    cerrar: async function () {
          //  alert('Hola 2' )
       // console.log(this.abierto)
       this.abierto=false;

       cardContainer.style.width = '0';
       backOverlay.classList.add('hidden');


    }
  },
  components: {Loading,
    Header,
    Footer,
    Iframe,
    CarouselPromotions,
    CarouselHeroBanner,
    CarouselDishPackages,
    CarouselPromotionsBanner,
    TitleComponent,
    SearchComponent,
  },
  watch:{
    value( val ){
      console.log( val )
    }
  },
  computed:{
    ...mapGetters({
      componentData: 'getComponentData',
      mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
    }),
    ...mapState({
      loading: state => state.Home.loading ,
    }),
  },
  setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const pageNumber = computed(() => {
        return  store.state.Home.pageNumber  
      })
      watch(pageNumber, (val ) => {
        const pageNumber = { value : val }
        store.dispatch( 'callHomeData', pageNumber  )
      }) 
      const callData = () => {
        store.dispatch( 'callNavbarData' )
        store.dispatch( 'callFooterData' )
        store.dispatch( 'callHomeData', pageNumber )
      }
      const getSlug = async () => {
        if( route.params.pathMatch ){
          const slug =  route.params.pathMatch[0]
          const exist = await store.dispatch('callAllPages', slug)
          if ( exist ) {
              callData()
          } else {
            router.push( {name: "PageNotFound"} )
          }
        } else {
          callData()
        }
      }
   
     getSlug()

  }
}




</script>
<style scoped>
.home-component{
  min-height: 80vh;
  position: relative;
}



@keyframes fadeInMoveUp {
    from {
        opacity: 0;
        transform: translateY(100px); /* Desplazamiento desde abajo */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Posición original */
    }
}


.mainVentana{
    position: fixed;
    bottom: 300px;
    z-index: 9999;
}

.StyleBot{
    max-width:100%;
    height: 500px;
    border-radius: 15px;
    background-color: #52727f;
    padding: 5px; 
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    animation: fadeInMoveUp 2s ease-in-out; /* Duración de 2 segundos, puedes ajustarla */
}


.btnCerrar{
    float: right;
    border: none;
    background-color: #e11c27;
    color: #ffffff;
    padding:5px 10px;
    border-radius: 25px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.0rem;
    margin-bottom: 5px;
    margin-right: 10px;
}





.container {
  width: 600px; /* Ancho del contenedor, ajusta según tus necesidades */
  margin: 0 auto; /* Centrar el contenedor */
}

.floating-image {
  float: left; /* Hace que la imagen flote a la izquierda */
  margin-right: 20px; /* Espacio entre la imagen y el texto */
  margin-bottom: 10px; /* Espacio opcional bajo la imagen */
}

.cardChat {
  z-index: 4000000000;
            background: #ffffff;
        }
        .back-over {
            background: black;
            opacity: 0.5;
            z-index: 110;
        }
        .scale-in {
            width: 81px;
            right: 3.2rem;
        }
        .scale-in:hover {
            -ms-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            transition: transform .2s;
        }
        .help-div {
            background: red;
            position: fixed;
            right: 0.7rem;
            bottom: 2.8em;
            color: white;
        }
        .help-div p {
            padding:0.2em 0.5em;
            font-weight: 600;
        }



@media only screen and (min-width:20em) and (max-width:23.4375em) { 
	.StyleBot{
        width:100%;
        height: 500px;
        border-radius: 15px;
        background-color: #52727f;
        padding: 5px; 
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    }
}





</style>