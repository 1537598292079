<template>
  <div class="home" :style="mobileBodyHeight">
      <Loading v-model:active="Subscription.loading"
      :can-cancel="true"
      :loader="'dots'"
      :color="'#D6212E'"
      :height="130"
      :width="130"
      :opacity="0.3"
      :is-full-page="true"
      >  </Loading>
    <Header />
    <div ref="bodyRef_" class="mx-auto xs:my-0  md:mx-0 md:container xl:container lg:container xl:p-10 xs:mx-0 xs:w-full sm:w-full relative xs:flex xs:flex-col sm:flex sm:flex-col xs:pb-24 sm:pb-24">
        <CheckoutNavigation :step="2" class="xs:order-1 sm:order-1" v-show="!isShowDetails"/>
        <div class="xs:order-2 sm:order-2 flex justify-between xs:flex-col xs:w-full lg:container xl:container 2xl:container m-auto md:px-6 lg:px-4 xl:px-8 2xl:px-14">
          <div class="md:w-6/12 lg:w-5-12 xl:5-/12 xs:w-full sm:w-full">
            <Register ref="RegisterFlow" @onShowDetails="onShowDetails" v-show="!isShowDetails"/>
          </div>
          <div class="md:w-5/12 lg:w-5-12 xl:5-/12 xs:xs-full sm:sm-full xs:hidden sm:hidden ">
            <Details v-show="!isShowDetails"/>
          </div>
        </div>
        <div class="w-full md:hidden lg:hidden absolute top-0 details-mobile xs:order-3 sm:order-3 xs:relative sm:relative" v-show="isShowDetails">
            <Details />
        </div>

        <div class="xs:visible sm:visible invisible bottom-nav flex items-center bg-white w-full p-5 justify-center step-mobile"  v-if="isShowDetails">
            <button class="btn-black mr-4" @click.prevent="onShowDetails()"> Regresar </button>
        </div> 
    </div>
    <Footer /> 
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CheckoutNavigation from '@/components/checkout/Navigation.vue'
import Register from '@/components/checkout/Register.vue'
import Details from '@/components/checkout/Details.vue'
import Footer from '@/components/Footer.vue'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapState, mapGetters, useStore } from 'vuex';
import { ref, isRef } from '@vue/reactivity'
import { inject, onMounted, onUnmounted } from '@vue/runtime-core'

export default {
  name: 'Home',
  components: {
    Header,
    CheckoutNavigation,
    Loading,
    Register,
    Footer,
    Details
  },
  computed: {
    ...mapGetters({
      mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
    }),
    ...mapState(['Subscription'] )
  },
  setup(){
      const bodyRef_ = ref(0)
      const store = useStore()
      const currentMediaQuery_ = inject('mq')
      onMounted(() => { 
        const currentMediaQuery = currentMediaQuery_.current
        const bodyRef = bodyRef_.value
        const bodyIsRef =  isRef(bodyRef_)
        store.commit('SET_ISREGISTERPAGE', true)     
        store.commit('SET_MOBILE_BODY_HEIGHT', {currentMediaQuery, bodyRef,  bodyIsRef})  
      })
      onUnmounted(() => {
        store.commit('STOP_INTERVAL_MOBILE', true) 
      })
      const isShowDetails = ref( false )
      const RegisterFlow = ref(null)

      const onShowDetails = () =>{
        isShowDetails.value = !isShowDetails.value
      }

      return {  isShowDetails ,  RegisterFlow, onShowDetails, currentMediaQuery_, bodyRef_ }
  }
}
</script>
<style scoped>
  .bottom-nav{
    position: fixed;
    bottom: 0;
  z-index: 2
  }
  .details-mobile{
    height: 100%;
    min-height: 100vh;
    z-index: 1;
  }
  @media screen and (min-width:768px) and (max-width:991px) {
      .page_registro._registro {
        width: 100%;
     }
  }
  
</style>