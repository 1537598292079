
export const SET_ALL_PAGES = ( state, payload ) => {
    state.all = payload 
}

export const  LOADING_PREVIEW = ( state, boolean ) =>{
    state.loading  = boolean 
}

export const SET_PREVIEW_MODULE = ( state, data ) => {
    state.preview = data
}

export const  ERROR_PREVIEW = ( state, string ) =>{
    state.error  = string 
}

export const SET_CURRENT_LINK_PROMOTION = ( state, link ) =>{
    state.linkPromotion =  link
}