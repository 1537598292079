export const getHomeData = ( state) => {
    return state.home
}

export const getFrankyIframe = ( state ) => {
    try{

        if(state.franky){
        if( Object.keys( state.franky ).length != 0 ) {
        let dataObj  = state.franky.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let iframe  = dataObj.find( ( { content } ) => { 
                if ( content ){
                    if( content.template.name ){
                        return content.template.name.toLowerCase()  == 'iframe sencillo'   
                    }
                }
            })
            return  iframe.content.content_values[0]
        }
    }
    }
    } catch( err) {
        console.log( err )
    }
}

export const getHomeHeroBanner = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if ( content ){
                    if( content.template ){
                        return content.template.name.toLowerCase()  == 'hero'   
                    }
                }
            })
            if( banner ){
                let group   = banner.content.content_values_grouped 
                let data  = { list : [] }
                    group.forEach( (key  ) => {
                        let group =  {}
                        key.forEach(  ( key  ) =>{
                            group[key.field] = key.value
                        }) 
                        data['list'].push(group)
                        data['title'] = banner.content.titulo
                })
                
                return data
            }
            
        }
    } catch( err) {
        console.log( err )
    }
}
export const getHomePromotionsTitle = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let title  = dataObj.find( ( { content } ) => {  
                    if(content.template) {
                        return content.template.name.toLowerCase()  == 'titulo simple'
                    }
                })
            if( title ){
                let data   = title.content.content_values[0]
                return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}

export const getHomePromotionsBanner = ( state) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'promociones'
                }
            })
            if( banner ) {
            let group   = banner.content.content_values_grouped 
            
            let data  = { list : [] }
                group.forEach( (key  ) => {
                    let group =  {}
                    key.forEach(  ( key  ) =>{
                        group[key.field] = key.value
                    }) 
                    data['list'].push(group)
                    data['title'] = banner.content.titulo
               })
               
            return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}
export const getPackageDataList = ( state, getters, rootState ) => {
    try{
        let dataObj = rootState.Home.packages.dataObj
        let title   = ""
        if( dataObj ){
            dataObj = dataObj.content_page 
            let packages  = dataObj.filter( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'grupo sencillo'
                }
            })
            let p = [ ]
            packages.map( ( {content}  )  => {
                let con = content.content_values 
                con.forEach( (key, ) =>{
                   key.custom_value.packages.forEach( (keys, ) =>{
                        p.push({ package : keys.package , serialize : JSON.parse(  keys.package.value ), group: keys  })
                    })
                })
            })
            let data  = { list : p, title: title }
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getHomePackageData = ( state, getters,  rootState  ) => {
    try{
        let dataObj = rootState.Home.home.dataObj
        let title   = ""
        if( dataObj ){
            dataObj = dataObj.content_page 
            let packages  = dataObj.filter( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'grupo sencillo'
                }
            })
            let p = [ ]
            packages.map( ( {content}  )  => {
                let con = content.content_values 
                con.forEach( (key, ) =>{
                   key.content_values.forEach( (keys, idxs ) =>{
                        p.push({ package : key.content_values[idxs].package  , serialize : JSON.parse(  key.content_values[idxs].package.value ) })
                    })
                })
            })
            let data  = { list : p, title: title }
            return data
        }
    } catch( err) {
        console.log( err )
    }
}

export const getHomePromotionsSlim = ( state ) => {
    try{
        let dataObj     = state.home.dataObj
        if( dataObj ){
            dataObj = dataObj.content_page 
            let banner  = dataObj.find( ( { content } ) => { 
                if(content.template) {
                    return content.template.name.toLowerCase()  == 'slim'
                }
            })
            if( banner ){
                let group   = banner.content.content_values_grouped 
                let data  = { list : [] }
                    group.forEach( (key  ) => {
                        let group =  {}
                        key.forEach(  ( key  ) =>{
                            group[key.field] = key.value
                        }) 
                        data['list'].push(group)
                        data['title'] = banner.content.titulo
                })
                
                return data
            }
        }
    } catch( err) {
        console.log( err )
    }
}


const getIframeData =  ( contentData ) => {
    return  contentData.content.content_values[0]
}
const getPromotionsData =  ( contentData ) => {

    let group   = contentData.content.content_values_grouped      
    let data  = { list : [] }
        group.forEach( (key  ) => {
            let group =  {}
            key.forEach(  ( key  ) =>{
                group[key.field] = key.value
            }) 
            data['list'].push(group)
            data['title'] = contentData.content.titulo
       })
       
    return data
    
}
const getPackageData = ( contentData ) => {
    let p = [ ]
    let legal = ""
    let con = contentData.content.content_values 
        con.forEach( (key ) =>{
            legal =  key.custom_value.legal
            if( key.custom_value.packages ){
                key.custom_value.packages.forEach( (keys ) =>{
                    p.push({ package : keys.package  , serialize : JSON.parse(  keys.package.value  ), group : keys })
                })
            }
        })
    
    let data  = { list : p, title: contentData.content.titulo, legal : legal   }
    return data

}
const getListData =  ( contentData ) => {
    let group   = contentData.content.content_values_grouped 
    let data  = { list : [] }
        group.forEach( (key  ) => {
            let group =  {}
            key.forEach(  ( key  ) =>{
                group[key.field] = key.value
            }) 
            data['list'].push(group)
            data['title'] = contentData.content.titulo
       })
    return data
}
const getTitleData  =  ( contentData ) => {
    let group   = contentData.content.content_values 
    let data  = { list : [] }
        group.forEach( ( key  ) => {
            data[key.field] = key.value
       })
    return data
}
export  const getComponentData = ( state, getters, rootState ) => {
    
    let content = rootState.Home.home.dataObj.content_page
    let data = []
    if( content && content.length > 0 ){
        content.forEach( ( contentData  ) =>{
            switch(contentData.content.template.name.toLowerCase() ){
                case 'iframe sencillo':
                    data.push({ name : 'Iframe', data : getIframeData( contentData ) })

                break;
                case 'promociones':
                    data.push({ name : 'CarouselPromotions', data : getPromotionsData ( contentData ) })
                break;

                case  'grupo sencillo':
                    data.push({ name : 'CarouselDishPackages', data : getPackageData( contentData ) })

                break;

                case 'slim':
                    data.push({ name : 'CarouselPromotionsBanner', data : getListData( contentData ) })

                break

                case 'hero':
                    data.push({ name : 'CarouselHeroBanner', data : getListData( contentData ) })
                break;

                case 'titulo simple':
                    data.push({ name : 'TitleComponent', data : getTitleData( contentData ) })
                break;

                case 'titulo':
                    data.push({ name : 'TitleComponent', data : getTitleData( contentData ) })
                break;

                case 'buscador canales':
                    data.push({ name : 'SearchComponent', data : []  })
                break;
            }
        })
    }
        return data
    }