<template>
  <div class="home _resumen" :style="mobileBodyHeight">
    <Header />
    <div ref="bodyRef_" class="mx-auto my-5 xs:my-0 xs:mx-0 md:mx-0 md:container xl:container lg:container xs:w-full sm:w-full xl:p-10 xs:flex sm:flex xs:flex-col sm:flex-col">
        <CheckoutNavigation :step="2"/>
        <div class="flex mt-5 justify-center">
          <div class="w-6/12 xs:w-full sm:w-full md:w-full">
            <Resumen />
          </div>
        </div>
    </div>
    <Footer /> 
  </div>
</template>

<style scoped>
  @media only screen and (min-width:768px) and (max-width:991px) { 
    .home._resumen {
      min-height:100vh !important
    }
  }
</style>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import CheckoutNavigation from '@/components/checkout/Navigation.vue'
import Resumen from '@/components/checkout/Resumen.vue'
import Footer from '@/components/Footer.vue'
import { mapGetters, useStore } from 'vuex';
import { inject, onUnmounted, onMounted } from '@vue/runtime-core'
import { ref, isRef } from '@vue/reactivity'

export default {
  name: 'Home',
  components: {
    Header,
    CheckoutNavigation,
    Footer,
    Resumen
  },
  computed: {
    ...mapGetters({
      mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
    })
  },
  setup(){
    const bodyRef_ = ref(0)
    const store = useStore()
    const currentMediaQuery_ = inject('mq')
    /* eslint-disable */
    gtag('event', 'conversion', {'send_to': 'AW-10798387531/rVJZCImv74ADEMuiiZ0o'}); 
    /* eslint-enable */

    onMounted(() => { 
      const currentMediaQuery = currentMediaQuery_.current
      const bodyRef = bodyRef_.value
      const bodyIsRef =  isRef(bodyRef_)
      store.commit('SET_MOBILE_BODY_HEIGHT', {currentMediaQuery, bodyRef, bodyIsRef})        
    })
    onUnmounted(() => {
      store.commit('STOP_INTERVAL_MOBILE', true)
    })
    return {currentMediaQuery_, bodyRef_} 
  }
}
</script>
