<template>
  <div class="modal-container">
    <div class="modal _mapcontainer">
      <div
        class="
          modal-header
          xs:justify-center
          sm:justify-center
          xs:flex-col
          sm:flex-col
          xs:z-10
          sm:z-10
        "
      >
        <div
          class="
            text-3xl
            font-bold
            text-center
            xs:visible
            sm:visible
            md:hidden
            lg:hidden
            xl:hidden
            2xl:hidden
          "
        >
          ON INTERNET
        </div>
        <a href="#" class="close" @click.prevent="onClose">
          <img src="@/assets/images/icon-close.png" alt="close" />
        </a>
        <p
          class="
            pt-3
            pb-1
            xs:visible
            sm:visible
            md:hidden
            lg:hidden
            xl:hidden
            2xl:hidden
            text-center text-sm
          "
        >
          Ingresa tu dirección para revisar tu cobertura
        </p>
        <GMapMap
          :center="center"
          :zoom="15"
          map-type-id="roadmap"
          style="width: 100%; height: auto"
          class="xs:visible sm:visible md:hidden lg:hidden xl:hidden 2xl:hidden"
        >
          <GMapAutocomplete
            class="input-autocomplete bg-gray-200 font-gray-900"
            placeholder="Postal Code, Avenida Arteaga y Salazar 120, Contadero, Cuajimalpa, "
            @place_changed="setPlace"
            ref="addressAutoMobile"
          >
          </GMapAutocomplete>
        </GMapMap>
      </div>
      <div class="modal-body" v-if="coverageLoading" ref="bodyRef_">
        <Skeletor width="50%" class="mx-auto" />
        <Skeletor width="80%" class="mx-auto" />
        <Skeletor width="100%" height="50px" pill class="mr-3 my-5" />
        <Skeletor width="100%" height="300px" class="mr-3 mb-2" />
        <Skeletor width="40%" class="mr-2" />
        <Skeletor width="40%" class="mr-2" />
        <Skeletor width="100%" class="mr-2" />
        <Skeletor width="100%" class="mr-2" />
        <Skeletor width="40%" height="40px" pill class="mx-auto mt-2" />
      </div>
      <div
        class="modal-body _map xs:px-0 sm:px-0"
        v-if="checkOnCoverage && hasOnCoverage && !coverageLoading"
        ref="bodyRef_"
      >
        <p class="text-2xl font-bold text-center">ON INTERNET</p>
        <p>Ingresa tu dirección para revisar tu cobertura</p>
        <div class="relative mt-28 xs:mt-5 sm:mt-5">
          <GMapMap
            :center="center"
            :zoom="15"
            map-type-id="roadmap"
            style="width: 100%; height: 300px"
          >
            <GMapAutocomplete
              class="input-autocomplete bg-gray-200 font-gray-900"
              placeholder="Postal Code, Avenida Arteaga y Salazar 120, Contadero, Cuajimalpa, "
              @place_changed="setPlace"
              ref="addressAuto"
              id="GMAPAutocomplete"
            >
            </GMapAutocomplete>
            <span class="text-red errorPostal">{{ errorPostalCode }}</span>
            <GMapMarker
              :key="marker.id"
              v-for="marker in markers"
              :position="marker.position"
              @dragend="onDrag"
              :draggable="true"
            />
          </GMapMap>
        </div>
        <p
          class="
            text-gray-500 text-left
            mt-2
            xs:hidden
            sm:hidden
            md:visible
            lg:visible
            xl:visible
            2xl:visible
          "
        >
          Coordenadas: Lat: {{ lat }}, Lng: {{ long }}
        </p>
        <p
          class="
            text-left
            mt-2
            xs:hidden
            sm:hidden
            md:visible
            lg:visible
            xl:visible
            2xl:visible
          "
        >
          Si el localizador no esta situado exactamente en tu domicilio,
          arrástralo a la posición correcta.
        </p>
        <button
          class="
            btn btn-red
            mt-5
            xs:hidden
            sm:hidden
            md:visible
            lg:visible
            xl:visible
            2xl:visible
          "
          @click.prevent="onReview"
        >
          Revisar cobertura
        </button>
        <button
          class="
            btn btn-black
            mt-5
            ml-2
            xs:hidden
            sm:hidden
            md:visible
            lg:visible
            xl:visible
            2xl:visible
          "
          v-show="isReturnHome"
          @click="goToHome"
          v-if="$route.name == 'Register'"
        >
          Ir al inicio
        </button>
      </div>
      <div class="modal-body _2" v-if="!hasOnCoverage">
        <!--  -->
        <img
          src="@/assets/images/icon-sad.png"
          alt="sad"
          class="mx-auto my-2 mb-5"
        />
        <p class="text-2xl font-bold text-center mb-5 xs:pt-12 sm:pt-12">
          ¡Oh no!
        </p>
        <p v-if="hasNoCoverageHasNoPlanes" class="xs:px-8 sm:px-8 md:px-8">
          Por el momento <strong> no tenemos </strong> cobertura de
          <strong> ON Internet</strong> para el domicilio que ingresaste, prueba
          de nuevo verificando que tu dirección sea correcta o continua con el
          pago <strong> sin ON Internet.</strong>
        </p>
        <p
          v-if="hasNoCoverageButHasPlanes"
          class="mt-5 xs:px-8 sm:px-8 md:px-8"
        >
          Por el momento no tenemos cobertura de ON Internet para el paquete que
          seleccionaste sin embargo si hay cobertura para paquetes con:
        </p>
        <p class="mt-1" v-for="coverage in availOnCoverage" :key="coverage">
          <span v-if="coverage == 'INTERNET_ON_5'">
            ON Inalámbrico Internet 5
          </span>
          <span v-if="coverage == 'INTERNET_ON_10'">
            ON Inalámbrico Internet 10
          </span>
          <span v-if="coverage == 'INTERNET_ON_20'"
            >ON Inalámbrico Internet 20
          </span>
        </p>

        <div class="modal-footer mt-5">
          <button class="btn btn-black" @click.prevent="onTryAgain">
            Probar de nuevo
          </button>
          <button class="btn btn-red" @click.prevent="goToPackages">
            Ver paquetes
          </button>
        </div>
      </div>
      <div
        class="modal-footer _map mt-5 flex flex-col justify-center"
        v-if="!isDone"
      >
        <p class="text-center mb-2 text-xs">
          Si el localizador no esta situado exactamente en tu domicilio,
          arrástralo a la posición correcta.
        </p>
        <div class="flex flex-row justify-center">
          <button class="btn btn-red mt-5" @click.prevent="onReview">
            Revisar cobertura
          </button>
          <button
            class="btn btn-black mt-5 ml-2"
            @click="goToHome"
            v-show="isReturnHome"
            v-if="$route.name == 'Register'"
          >
            Ir al inicio
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, useStore } from "vuex";
import { ref, isRef } from "@vue/reactivity";
import { inject, onMounted, onUnmounted } from "@vue/runtime-core";
import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "ModalMap",
  props: ["onShow", "productData"],
  emits: ["onClose", "onSubmit"],
  computed: {
    ...mapGetters({
      mobileBodyHeight: "GET_MOBILE_BODY_HEIGHT",
    }),
  },
  components: {
    Skeletor,
  },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const isDone = ref(false);
    const hasDragged = ref(false);
    const mapRef = ref(null);
    const lat = ref(0);
    const long = ref(0);
    const markers = ref([]);
    const checkOnCoverage = ref(true);
    const hasOnCoverage = ref(true);
    const errorPostalCode = ref("");
    const coverageLoading = ref(false);
    const hasPostalCode = ref(false);
    const googleAdddress = ref({});
    const isReturnHome = ref(false);
    const availOnCoverage = ref("");
    const hasNoCoverageButHasPlanes = ref(false);
    const hasNoCoverageHasNoPlanes = ref(false);
    const bodyRef_ = ref(0);
    const store = useStore();
    const currentMediaQuery_ = inject("mq");
    const addressAuto = ref("");
    const addressAutoMobile = ref("");
    onMounted(() => {
      const currentMediaQuery = currentMediaQuery_.current;
      const bodyRef = bodyRef_.value;
      const bodyIsRef = isRef(bodyRef_);
      store.commit("SET_MOBILE_BODY_HEIGHT", {
        currentMediaQuery,
        bodyRef,
        bodyIsRef,
      });
      document.documentElement.style.overflow = "hidden";
      window.scrollTo(0, store.state.Navbar.scrollPosition);
    });
    onUnmounted(() => {
      store.commit("STOP_INTERVAL_MOBILE", true);
      document.documentElement.style.overflow = "auto";
      store.commit("SET_ISSHOWFOOTER", true);
    });

    let center = ref({ lat: 0, lng: 0 });

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        alert("Enable your location ");
      }
    };
    const onClose = () => {
      checkOnCoverage.value = true;
      hasOnCoverage.value = true;
      coverageLoading.value = false;
      availOnCoverage.value = "";
      hasPostalCode.value = false;
      errorPostalCode.value = "";
      context.emit("onClose");
    };
    const onTryAgain = () => {
      hasOnCoverage.value = true;
      checkOnCoverage.value = true;
      isDone.value = false;
    };

    const onSubmit = (validate) => {
      context.emit("onSubmit", { ...props.productData, validate: validate });
    };

    const goToPackages = () => {
      context.emit("onClose");
      store.commit("SET_PAGE_NUMBER", 16);
    };

    const onDrag = async (data) => {
      const dataLat = data.latLng.lat();
      const dataLong = data.latLng.lng();
      lat.value = dataLat;
      long.value = dataLong;
      center.value = {
        lat: dataLat ? dataLat : 19.4326077,
        lng: dataLong ? dataLong : -99.133208,
      };
      markers.value = [
        {
          position: {
            lat: dataLat ? dataLat : 19.4326077,
            lng: dataLong ? dataLong : -99.133208,
          },
        },
      ];
      const dataResult = await getAddressFromLatLong({
        lat: lat.value,
        lng: long.value,
      });
      console.log(dataResult);
      if (dataResult.data) {
        if (dataResult.data.results.length > 0) {
          setPlace(dataResult.data.results, "drag");
        }
      }
    };

    const onReview = async () => {
      // const data = {lat:"19.3238531",lng:"-99.15750220000001",plan: "INTERNET_ON_20"}
      const data = {
        lat: lat.value,
        lng: long.value,
        plan: props.productData.package
          ? props.productData.package.erp_id
          : props.productData.erp_id,
      };
      availOnCoverage.value = "";
      coverageLoading.value = true;
      const onCoverage = await store.dispatch("getOnCoverage", data);
      coverageLoading.value = false;

      if (onCoverage.hasCoverage) {
        if (hasPostalCode.value) {
          if (route.name != "Register") {
            store.commit("SET_GOOGLE_ADDRESS", googleAdddress.value);
            onSubmit(true);
          } else {
            store.commit("SET_GOOGLE_ADDRESS", googleAdddress.value);
            onClose();
          }
        } else {
          errorPostalCode.value = "Por favor introduce una dirección válida";
          if (route.name != "Register") {
            isReturnHome.value = true;
          }
        }
      } else {
        hasPostalCode.value = false;
        hasOnCoverage.value = false;
        if (onCoverage.data) {
          if (onCoverage.data.altan.planes.length == 0) {
            hasNoCoverageHasNoPlanes.value = true;
            hasNoCoverageButHasPlanes.value = false;
          } else {
            hasNoCoverageButHasPlanes.value = true;
            hasNoCoverageHasNoPlanes.value = false;
            availOnCoverage.value = onCoverage.data.altan.planes;
          }
        } else {
          hasNoCoverageHasNoPlanes.value = true;
          hasNoCoverageButHasPlanes.value = false;
        }
        errorPostalCode.value = "Por favor introduce una dirección válida";
        if (route.name != "Register") {
          isReturnHome.value = true;
        }
      }
      isDone.value = true; // for mobile flagging only
    };
    const goToHome = () => {
      router.push({ name: "Home" });
    };

    const getAddressFromLatLong = (data) => {
      return store.dispatch("getAddressFromLatLong", data);
    };
    const showPosition = async (position) => {
      const posLat = position.coords.latitude;
      const posLong = position.coords.longitude;
      lat.value = posLat;
      long.value = posLong;
      if (props.productData) {
        center.value = {
          lat: posLat ? posLat : 19.4326077,
          lng: posLong ? posLong : -99.133208,
        };
        lat.value = posLat ? posLat : 19.4326077;
        long.value = posLong ? posLong : -99.133208;
        markers.value.push({
          position: {
            lat: posLat ? posLat : 19.4326077,
            lng: posLong ? posLong : -99.133208,
          },
        });
      }
    };

    const setPlace = async (data, type) => {
      errorPostalCode.value = "";
      const address = data.address_components;
      let postal_code;
      let tempData;
      if (!type) {
        postal_code = address.find((data) =>
          data.types.indexOf("postal_code") != -1 ? true : false
        );
      } else {
        // data.forEach((element) => {
        //   let address = element.address_components;
        //   let postal = address.find((data) =>
        //     data.types.indexOf("postal_code") != -1 ? true : false
        //   );
        //   if (postal) {
        //     postal_code = postal;
        //     tempData = element;
        //   }
        // });

        let mainLocation = data.find(
          (m) => m.geometry.location_type === "GEOMETRIC_CENTER"
        );

        if (mainLocation.formatted_address.includes("+")) {
          mainLocation = data.find(
            (m) => m.geometry.location_type === "ROOFTOP"
          );
        }

        postal_code = data[0].address_components.find((data) =>
          data.types.indexOf("postal_code") != -1 ? true : false
        );

        tempData = mainLocation;

        if (tempData) {
          data = tempData;
          const au = data.formatted_address;
          addressAuto.value.$el.value = au;

          addressAutoMobile.value.$el.value = au;
          addressAuto.value.$forceUpdate();
          addressAutoMobile.value.$forceUpdate();
        }
      }

      if (postal_code) {
        hasPostalCode.value = true;
        googleAdddress.value = data;
      } else {
        hasPostalCode.value = false;
        // errorPostalCode.value = "Establezca su código posta";
      }
      if (!type) {
        if (data.geometry) {
          let geoLat, geoLong;
          geoLat = data.geometry.location.lat();
          geoLong = data.geometry.location.lng();

          center.value = {
            lat: geoLat ? geoLat : 19.4326077,
            lng: geoLong ? geoLong : -99.133208,
          };
          markers.value = [
            {
              position: {
                lat: geoLat ? geoLat : 19.4326077,
                lng: geoLong ? geoLong : -99.133208,
              },
            },
          ];
          lat.value = geoLat ? geoLat : 19.4326077;
          long.value = geoLong ? geoLong : -99.133208;
          hasDragged.value = false;
        }
      } else {
        if (data.geometry) {
          let geoLat, geoLong;
          geoLat = data.geometry.location.lat;
          geoLong = data.geometry.location.lng;

          center.value = {
            lat: geoLat ? geoLat : 19.4326077,
            lng: geoLong ? geoLong : -99.133208,
          };
          markers.value = [
            {
              position: {
                lat: geoLat ? geoLat : 19.4326077,
                lng: geoLong ? geoLong : -99.133208,
              },
            },
          ];
          lat.value = geoLat ? geoLat : 19.4326077;
          long.value = geoLong ? geoLong : -99.133208;
          hasDragged.value = true;
        }
      }
    };

    getLocation();

    return {
      center,
      lat,
      long,
      mapRef,
      markers,
      onDrag,
      onSubmit,
      setPlace,
      onTryAgain,
      onReview,
      errorPostalCode,
      hasPostalCode,
      coverageLoading,
      checkOnCoverage,
      hasOnCoverage,
      isReturnHome,
      goToHome,
      availOnCoverage,
      hasNoCoverageButHasPlanes,
      hasNoCoverageHasNoPlanes,
      onClose,
      goToPackages,
      currentMediaQuery_,
      bodyRef_,
      isDone,
      addressAuto,
      addressAutoMobile,
      hasDragged,
    };
  },
};
</script>
<style >
.errorPostal {
  @apply text-red;
  position: absolute;
  top: -30px;
  left: 15px;
}
.pac-container {
  @apply absolute !important;
  z-index: 100000 !important;
}
.input-autocomplete {
  @apply p-4 my-3 rounded-full mr-1 w-full top-0 left-0 absolute;
  top: -100px;
}
@media screen and (min-width: 768px) {
  .modal-footer._map {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .modal-header .vue-map {
    display: none !important;
  }
  .modal-header {
    z-index: 99999999 !important;
  }
  .modal-body .vue-map-hidden {
    display: none;
  }
  .pac-item {
    padding: 15px 5px !important;
  }
  .pac-container {
    margin-top: 16px;
    border-radius: 15px;
    padding-bottom: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 10%);
  }
  .hdpi.pac-logo:after {
    right: 10px;
    position: relative;
  }
  .modal-body._map {
    height: 100% !important;
  }
  .modal-header .input-autocomplete {
    position: relative;
    top: unset;
    right: unset;
    margin: 0px;
    margin-top: 10px;
  }
  .modal-body._2 {
    min-height: 100vh;
    padding-top: 100px;
  }
}
</style>
<style scoped>
.modal-header {
  @apply relative xs:fixed sm:fixed xs:w-full sm:w-full xs:bg-white sm:bg-white xs:shadow-xl sm:shadow-xl xs:rounded-bl-2xl sm:rounded-bl-2xl xs:rounded-br-2xl sm:rounded-br-2xl xs:p-6 sm:p-6 xs:justify-end sm:justify-end xs:flex sm:flex xs:pb-4 sm:pb-4;
}

.modal-footer {
  @apply flex align-middle justify-center sm:fixed xs:bottom-0 sm:bottom-0 xs:fixed xs:bg-white sm:bg-white xs:p-6 sm:p-6 xs:pt-4 sm:pt-4 xs:flex sm:flex xs:w-full sm:w-full;
}
.modal-body {
  @apply md:px-5 lg:px-5 xl:px-5 2xl:px-5 pt-6 pb-3 text-center xs:pt-24 sm:pt-24 xs:pb-5 sm:pb-5;
}
.modal {
  @apply absolute left-0 top-0 bg-white rounded-2xl px-5 pb-6 xs:px-0 sm:px-0;
  width: 590px;
  left: 50%;
  transform: translate(-50%, 20%);
}
.modal-container {
  @apply w-full h-screen fixed top-0 left-0 z-0 xs:absolute sm:absolute;
  background: rgba(0, 0, 0, 0.5);
  content: "";
  z-index: 9999;
}
.modal-footer .btn {
  @apply mx-2 my-2;
}
@media screen and (min-width: 992px) and (max-width: 1366px) {
  .modal {
    transform: translate(-50%, 5%);
  }
  .modal-body {
    max-height: 85vh;
  }
}
@media screen and (min-width: 992px) {
  .modal {
    max-height: 90vh;
    width: 900px;
  }
  .modal-header {
    max-height: 10vh;
  }
  .modal-footer {
    max-height: 15vh;
  }
}
@media screen and (max-width: 767px) {
  .modal {
    @apply w-full;
    transform: unset;
    border-radius: 0;
    left: unset;
  }
  .modal-header .close {
    @apply xs:absolute sm:absolute xs:flex sm:flex;
    top: 20px;
    right: 20px;
    left: unset;
    width: 40px;
    height: 40px;
    padding: 0.7rem;
  }
  .modal-body._map .vue-map-container {
    height: calc(100vh - 332px) !important;
  }
  .modal-footer {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
  }
}
</style>