<template>
	<button
		:class="`${selected} ${hide}`"
		@click="moveCard"
		type="button"
		class="anim-flex rounded-full py-2 h-4"
		aria-label="card-btn"
	></button>
</template>
<script>
export default {
	props: {
		index: Number,
		initSelected: Boolean,
	},
	emits: ["moveCard"],
	created() {
		this.$parent.$data.buttons.push(this);
	},
	mounted() {
		if (this.initSelected) this.moveCard();
	},
	data() {
		return {
			isSelected: false,
			isHide: false,
		};
	},
	computed: {
		selected() {
			return this.isSelected ? `card-btn-selected` : `card-btn delay-100`;
		},
		hide() {
			return this.isHide ? "hidden" : "";
		},
	},
	methods: {
		moveCard() {
			this.isSelected = true;
			this.$emit("moveCard", { index: this.index, btn: this });
		},
		select() {
			this.isSelected = true;
		},
		deselect() {
			this.isSelected = false;
		},
		hideButton() {
			this.isHide = true;
		},
		showButton() {
			this.isHide = false;
		},
	},
};
</script>

<style scoped>
.card-btn {
	flex: 0 0 24px;
	@apply bg-gray-300;
}
.card-btn-selected {
	flex: 0 0 80px;
	@apply bg-red;
}
</style>