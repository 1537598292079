const findArrayIndex = ( array, name ) => {
    if( array && array.length != 0 ){

        const arr = array.findIndex( item => item.category === name && item.data != 0)
        return arr
    }
}

const moveArray = (arr, fromIndex, toIndex) =>{
    if( arr && arr.length != 0 ){
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr 
    }
    
}

export const getProducts = ( state) => {
    return state.products
}
export const getProductsList = ( state ) => {
    return state.products.dataObj
}
export const getProductInformation =  ( state ) => {
    const local = localStorage.getItem('SET_PACKAGES_INFORMATION');
    return  ( local ? JSON.parse(local) :  state.information )
  }
export const getLogosCategories = ( state ) => {
    const logos  =  state.logos
    console.log( logos )
    let normCat = []
    if ( logos ){
    let categories = []
    if( logos.canalesDM  ){
        logos.canalesDM.forEach(element => {
            element.categorias.forEach( data =>{    
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesDM.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }
    if( logos.canalesAbiertaHD  ){
        logos.canalesAbiertaHD.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesAbiertaHD.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }

    if( logos.canalesDish  ){
        logos.canalesDish.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesDish.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }

    if( logos.canalesAbierta ){
        logos.canalesAbierta.forEach(element => {
            element.categorias.forEach( data =>{
                if( categories.indexOf(data) == -1) {
                    categories.push(data)
                }
            });
        });

        categories.forEach( data => {
            let list = logos.canalesAbierta.filter( ({categorias}) => categorias.indexOf(data) != -1 )
                normCat.push( { 
                    category : data,
                    data : list } )
            })
    }
} 

    let index = findArrayIndex( normCat, 'TV abierta' )
    if(index >= 0){
        normCat = moveArray( normCat, index, 0 )
    }
    index = findArrayIndex( normCat, 'TV abierta HD' )
    if(index >= 0 ){
        normCat = moveArray( normCat, index, 1 )
    }
    return normCat
}