<template>
<div class="modal-container" >
    <div class="modal">
        <div class="modal-header mt-9 xs:w-full sm:w-full md:w-full xs:text-center sm:text-center md:text-center">
            <a href="#" class="close xs:hidden sm:hidden md:flex lg:visible xl:visible 2xl:visible" @click.prevent="$emit('onClose');">
               <img src="@/assets/images/icon-close.png" alt="close">
            </a>
            <div class="flex justify-between xs:flex-col sm:flex-col md:flex-col">
                <div class="xs:flex xs:flex-row sm:flex sm:flex-row md:flex md:flex-row justify-between py-3 px-5 xs:w-full sm:w-full  md:w-full xs:shadow-md xs:rounded-br-xl xs:rounded-bl-xl sm:shadow-md sm:rounded-br-xl sm:rounded-bl-xl md:shadow-md md:rounded-br-xl md:rounded-bl-xl lg:pl-0 xl:pl-0 2xl:pl-0">
                    <div class="xs:flex xs:flex-col sm:flex sm:flex-col md:flex md:flex-col justify-center ">
                        <p class="text-red text-4xl font-semibold xs:text-2xl sm:text-2xl md:text-2xl md:text-2xl"> {{productData.package.name}}</p>
                    </div>
                    <a href="#" class="close _2 xs:visible sm:visible md:visible lg:hidden xl:hidden 2xl:hidden" @click.prevent="$emit('onClose');">
                        <img src="@/assets/images/icon-close.png" alt="close">
                    </a>
                </div>
                <div class="flex xs:justify-center sm:justify-center md:justify-center  xs:py-5 sm:py-5 pb-3 md:mt-6">
                    <button class="rounded-tl-2xl rounded-bl-2xl px-5 py-3 text-18" @click="$emit('setChannels', true);" :class="`${ isActive2  }`"> Canales </button>
                    <button class="rounded-tr-2xl rounded-br-2xl px-5 py-3 text-18"   @click="$emit('setChannels', false);" :class="`${ isActive }`"> Información </button>
                </div>
            </div>
        </div>
        <div class="modal-body" ref="bodyRef_">
            <div class="flex flex-wrap justify-center xs:flex-col sm:flex-col" v-show="isChannels">
                <template v-if="loading">
                    <div class="flex flex-col w-5/12 xs:w-full sm:w-full mr-2"  v-for="index in 4"  :key="index">
                        <div class="channels-container h-full f"  >
                            <Skeletor pill width="120px" height="20px" class="tag" />
                            <div class="mt-4 flex flex-wrap gap-3 justify-center"  >
                            <Skeletor circle width="50px" height="50px" v-for="index in 6" :key="index"/> 
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="logos">
                        <div class="flex flex-col w-5/12 xs:w-full sm:w-full mr-2" v-for="(logo, index ) in logos " :key="index" v-show="logo.data.length !=0"> 
                            <div class="channels-container h-full" v-if="logo.data.length != 0">

                                <div class="md:flex md:flex-col" v-if="logo.category != 'Streaming'">
                                <div class="tag text-18 md:text-center"> {{logo.category}} </div>
                                <span class="text-2xl md:text-center xs:text-xl sm:text-xl md:text-xl pl-2"> {{logo.data.length}} Canales </span>
                                </div>



                                <div class="md:flex md:flex-col" v-if="logo.category == 'Streaming'">
                                <div class="tag text-18 md:text-center"> {{logo.category}} </div>
                                <span class="text-2xl md:text-center xs:text-xl sm:text-xl md:text-xl pl-2"> {{logo.data.length}} Plataformas </span>
                                </div>
                                <!-- <div class="md:flex md:flex-col">
                                    <div class="tag text-18 md:text-center"> {{logo.category}} </div>
                                    <span class="text-2xl md:text-center xs:text-xl sm:text-xl md:text-xl pl-2"> {{logo.data.length}} Canales </span>
                                </div> -->
                                <ul class="channels flex flex-wrap gap-3">
                                    <li v-for="(l , idx  ) in logo.data" :key="idx" class="flex-col justify-center flex rounded-full bg-gray-250">
                                        <div class="p-2">
                                            <img :src="l.url" :alt="l.canal">
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div style="height:300px" class="flex align-middle items-center">
                            No availables chanels for this package
                        </div>
                    </template>
                </template>
                
            </div>
              <div class="flex xs:flex-col sm:flex-col  md:justify-center md:flex-initial" v-show="!isChannels" v-if="productData">
                <div class="flex w-8/12 flex-col package_modal_info_container xs:w-full sm:w-full">
                    <div class="information-container flex-1 mt-2 xs:mr-0 sm:mr-0 md:mr-0 w-full flex flex-col">
                        <p class="text-red text-2xl font-semibold text-left xs:text-center sm:text-center md:text-center mb-3">{{ productData.serialize.title_list_1 }}</p>
                        <ul class="info flex-wrap flex gap-5 gap-y-2 xs:flex-col xs:flex-nowrap md:flex-nowrap">
                            <li v-for="index in productData.serialize.list_inform_1" :key="index" class="px-5 py-2"
                                :class="{'justify-between' : index.selecttemplate == 2, 'justify-center' : index.selecttemplate != 2, }">
                                <template v-if="index.selecttemplate == 1 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                </template>
                                <template v-if="index.selecttemplate == 2 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right"></p>
                                </template>
                                <template v-if="index.selecttemplate == 3 ">
                                    <img :src="index.image" alt="" v-if="index.type == 'image'">
                                     <p v-html="index.text1" class="font-medium text-xl text-red text-right leading-5"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                </template>
                                <template v-if="index.selecttemplate == 4 ">
                                    <img :src="index.image" alt="">
                                </template>
                            </li>
                        </ul>
                    </div>
                    <div class="information-container flex-1 mt-5">
                        <p class="text-red text-2xl font-semibold text-left xs:text-center sm:text-center md:text-center w-full mb-3">{{ productData.serialize.title_list_2 }} </p>
                        <ul class="info flex-wrap flex gap-5 gap-y-2 xs:flex-col xs:flex-nowrap">
                            <li v-for="index in productData.serialize.list_inform_2" :key="index" class="px-5 py-2"
                                :class="{'justify-between' : index.selecttemplate == 2, 'justify-center' : index.selecttemplate != 2, }">
                                <template v-if="index.selecttemplate == 1 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                </template>
                                <template v-if="index.selecttemplate == 2 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right"></p>
                                </template>
                                <template v-if="index.selecttemplate == 3 ">
                                    <img :src="index.image" alt="" >
                                    <p v-html="index.text1" class="font-medium text-xl text-red text-right leading-5"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                </template>
                                <template v-if="index.selecttemplate == 4 ">
                                    <img :src="index.image" alt="" >
                                </template>
                            </li>
                        </ul>
                    </div>
                    <div class="information-container flex mt-5 flex-col flex-wrap">
                        <p class="text-red text-2xl font-semibold text-left mb-3 xs:text-center sm:text-center md:text-center">{{ productData.serialize.title_list_3 }} </p>
                       <ul class="info flex-wrap flex gap-5 gap-y-2 xs:flex-col xs:flex-nowrap">
                            <li v-for="index in productData.serialize.list_inform_3" :key="index" class="px-5 py-2"
                                :class="{'justify-between' : index.selecttemplate == 2, 'justify-center' : index.selecttemplate != 2, }">
                                <template v-if="index.selecttemplate == 1 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                </template>
                                <template v-if="index.selecttemplate == 2 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right"></p>
                                </template>
                                <template v-if="index.selecttemplate == 3 ">
                                    <img :src="index.image" alt="">
                                    <p v-html="index.text1" class="font-medium text-xl text-red text-right leading-5"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                </template>
                                <template v-if="index.selecttemplate == 4 ">
                                    <img :src="index.image" alt="" >
                                </template>
                            </li>
                        </ul>
                    </div>
                     <div class="information-container flex mt-5 flex-col flex-wrap" v-if="productData.serialize.title_list_4">
                        <p class="text-red text-2xl font-semibold text-left mb-3 xs:text-center sm:text-center md:text-center">{{ productData.serialize.title_list_4 }} </p>
                       <ul class="info flex-wrap flex gap-5 gap-y-2 xs:flex-col xs:flex-nowrap">
                            <li v-for="index in productData.serialize.list_inform_4" :key="index" class="px-5 py-2"
                                    :class="{'justify-between' : index.selecttemplate == 2, 'justify-center' : index.selecttemplate != 2, }">
                                <template v-if="index.selecttemplate == 1 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                </template>
                                <template v-if="index.selecttemplate == 2 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right"></p>
                                </template>
                                <template v-if="index.selecttemplate == 3 ">
                                    <img :src="index.image" alt="" v-if="index.type == 'image'">
                                    <p v-html="index.text1" class="font-medium text-xl text-red text-right leading-5"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                </template>
                                <template v-if="index.selecttemplate == 4 ">
                                    <img :src="index.image" alt="" >
                                </template>
                            </li>
                        </ul>
                    
                    </div>
                     <div class="information-container flex mt-5 flex-col flex-wrap" v-if="productData.serialize.title_list_5">
                        <p class="text-red text-2xl font-semibold text-left mb-3 xs:text-center sm:text-center">{{ productData.serialize.title_list_5 }} </p>
                        <ul class="info flex-wrap flex gap-5 gap-y-2 xs:flex-col xs:flex-nowrap">
                            <li v-for="index in productData.serialize.list_inform_5" :key="index" class=" px-5 py-2" 
                                    :class="{'justify-between' : index.selecttemplate == 2, 'justify-center' : index.selecttemplate != 2, }">
                                <template v-if="index.selecttemplate == 1 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                </template>
                                <template v-if="index.selecttemplate == 2 ">
                                    <p v-html="index.text1" v-if="!index.type" class="text-18"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right"></p>
                                </template>
                                <template v-if="index.selecttemplate == 3 ">
                                    <img :src="index.image" alt=""  v-if="index.type == 'image'">
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                    <p v-html="index.text2" class="font-medium text-xl text-red text-right leading-5"></p>
                                </template>
                                <template v-if="index.selecttemplate == 4 ">
                                    <img :src="index.image" alt=""  >
                                </template>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex flex-col w-4/12 ml-6 _sidebar xs:w-full sm:w-full xs:mx-0 sm:mx-0 xs:hidden sm:hidden md:hidden lg:visible xl:visible 2xl:visible" 
                >
                    <div class="channels-container p-8" :style="{ background:`url(${ productData.serialize.img_fondo})`}" v-if="!productData.serialize.img_fondo">
                        {{ productData.serialize.img_fondo }}
                        <p class="text-28 font-semibold text-center text-maroon leading-8 xs:text-xl sm:text-xl">
                            {{ productData.serialize.text_principbn}}
                        </p>
                        <p class="text-xl mt-4 text-maroon xs:text-18 sm:text-18 xs:text-center sm:text-center">
                            {{ productData.serialize.text_secundbn}}
                        </p>
                        <a class="btn btn-black block mx-auto mt-11 xs:w-10/12 sm:w-10/12 xs:mt-5 sm:mt-5 text-center" 
                            v-if="productData.serialize.banner_redirect == 'I'" @click.prevent="setPageNumber(productData.serialize.banner_redirect_internal)"> {{ productData.serialize.text_btnbn}}</a>
                        <a class="btn btn-black block mx-auto mt-11 xs:w-10/12 sm:w-10/12 xs:mt-5 sm:mt-5 text-center" 
                            v-if="productData.serialize.banner_redirect == 'E'" :href="productData.serialize.banner_redirect_external">{{ productData.serialize.text_btnbn}}</a>
                    </div>

                    <div v-if="productData.serialize.img_fondo" class="channels-container p-8" :style="{ background:`url('${ productData.serialize.img_fondo}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center'}">
                       <p class="text-28 font-semibold text-center text-maroon leading-8 xs:text-xl sm:text-xl">
                            {{ productData.serialize.text_principbn}}
                        </p>
                        <p class="text-xl mt-4 text-maroon xs:text-18 sm:text-18 xs:text-center sm:text-center">
                            {{ productData.serialize.text_secundbn}}
                        </p>
                        <a class="btn btn-black block mx-auto mt-11 xs:w-10/12 sm:w-10/12 xs:mt-5 sm:mt-5 text-center" 
                            v-if="productData.serialize.banner_redirect == 'I'" @click.prevent="setPageNumber(productData.serialize.banner_redirect_internal)"> {{ productData.serialize.text_btnbn}}</a>
                        <a class="btn btn-black block mx-auto mt-11 xs:w-10/12 sm:w-10/12 xs:mt-5 sm:mt-5 text-center" 
                            v-if="productData.serialize.banner_redirect == 'E'" :href="productData.serialize.banner_redirect_external">{{ productData.serialize.text_btnbn}}</a>
                    </div>

                </div>
            </div>
            <div class="flex w-full flex-col package_modal_info_container xs:w-full sm:w-full md:w-full" v-show="!isChannels" >
                <p class="text-xs mt-8 text-left xs:mt-5 sm:mt-5 text-center" v-html="productData.serialize.txt_legales"> </p>
            </div>
        </div>
        <div class="modal-footer xs:w-full sm:w-full md:w-full">
            <!-- <button class="btn btn-black invisible" click.prevent="$emit('onClose')"> Personalizar</button> -->
            <button class="btn btn-red" @click.prevent="onSubmit"> {{productData.serialize.redir_esp}} </button>
        </div>
    </div>
    </div>
</template>

<style scoped>
    .tag {
        @apply text-white font-semibold mr-2 bg-red rounded-full py-2 px-4;
        box-shadow: 0px 0px 9px 2px rgba(214, 33, 46, 0.5);
        display: inline-table
    }
    .channels-container {
        @apply mb-2 w-full my-3 px-5 pt-5 pb-3 rounded-xl text-left;
        border: solid 1px #D6212E;
        background:#FDF4F4
    }
    .modal-header .close {
        top: -4.1rem;
        right:-1.5rem
    }
    ul.info li{
        @apply flex bg-gray-150 rounded-2xl align-middle items-center;
        min-height:59px;
        width:calc(50% - 15px);
        margin:1px 0px
    }
    ._sidebar .channels-container {
        padding-top:60px;
        margin-top:3.3rem;
        padding-bottom:24px;
        @apply xs:mt-6 sm:mt-6 xs:p-6 sm:p-6
    }

    ul.channels{
        @apply mt-6;
    }
    ul.channels li{
        width: 60px;
        height: 60px;
    }
    .modal-header{
        @apply relative px-10;
    }
    .modal-footer{
        @apply flex align-middle py-5 justify-center ;
    }
    .modal-body{
        @apply pl-10 pr-6 pt-6 text-center;
        max-height: 467px;
        overflow-y: scroll;
    }
    .modal{
        @apply absolute left-0 top-0 bg-white rounded-2xl ;
        width: 1200px;
        left: 50%;
        transform: translate(-50%, 20%);
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.15);
    }
    .modal-container{
        @apply  w-full h-screen fixed top-0 left-0 z-0; 
        background: rgba(0, 0, 0, 0.5);
        content:"";
        z-index: 9999999;
    }
    .modal-footer .btn{
        @apply mx-2
    }
    @media only screen and (max-width:991px) {
        .modal {
            @apply w-full rounded-none relative;
            transform:unset;
            left:unset
        }
        .modal-header .close._2 {
            position: relative;
            top:unset;
            left:unset; 
            right: unset;
            width:38px;
            height:38px;
            padding:12px
        }
        .modal-header {
            @apply mt-0 fixed bg-white;
            padding:0px;
        }
        .modal-container {
            @apply absolute h-full
        }
        .modal-footer {
            @apply fixed bottom-0 bg-white;
            box-shadow: 0px -4px 10px rgb(0,0,0,0.1)
        }
        .modal-body {
            @apply max-h-full h-auto;
            padding:20px;
            padding-top:calc(1rem + 155px);
            padding-bottom:calc(0rem + 120px)
        }
    }
    @media only screen and (min-width:1025px) and (max-width:1366px) { 
        .modal {
            width:90%;
            top:-80px;
        }
    }
    @media only screen and (min-width:992px) and (max-width:1024px) { 
        .modal {
            width:90%;
            top:-80px;
        }
    }
    @media only screen and (max-width:768px) {
        ul.info li {
            width:100%
        }
        .modal-header .close {
            min-width: 38px;
        }
    }
</style>


<script>
import { computed } from 'vue'
import { Skeletor } from 'vue-skeletor';
import { mapState, mapGetters, useStore } from 'vuex';
import { ref, isRef } from '@vue/reactivity'
import { inject, onMounted, onUnmounted } from '@vue/runtime-core'

export default {
    props:['onShow', 'isChannels', 'productData' ],
    emits:['onClose', 'onSubmit'],
    components:{
        Skeletor
    },
    computed:{
       ...mapGetters({
            logos: 'getLogosCategories',
            mobileBodyHeight: 'GET_MOBILE_BODY_HEIGHT'
        }),
        ...mapState({
            loading :  state => state.Products.loading
        })
    },
    setup( props, context ) {
        const bodyRef_ = ref(0)
        const store = useStore()
        const currentMediaQuery_ = inject('mq')
        onMounted(() => { 
            const currentMediaQuery = currentMediaQuery_.current
            setTimeout(()=>{
                if(currentMediaQuery == 'xs' || currentMediaQuery == 'sm') {
                    window.scrollTo(0,0)
                }
            },100) 
            const bodyRef = bodyRef_.value
            const bodyIsRef =  isRef(bodyRef_)  
            store.commit('SET_MOBILE_BODY_HEIGHT', {currentMediaQuery, bodyRef,  bodyIsRef})       
        })
        onUnmounted(() => {
            store.commit('STOP_INTERVAL_MOBILE', true)           
        })  
        const onSubmit = (  ) => {
             context.emit('onSubmit', props.productData )
        } 
        const isActive = computed(() => {
            return props.isChannels
                ? 'bg-white shadow-light' 
                : 'bg-lightred-50'  
        })
        const isActive2 = computed(() => {
            return !props.isChannels
                ? 'bg-white shadow-light' 
                : 'bg-lightred-50'  
        })
        const getLogos = async ( ) =>{
            await store.dispatch('getLogos', props.productData.package.erp_id ) 
        }
        const setPageNumber = ( number ) =>{
            context.emit('onClose', false)
            window.scrollTo(0, 0);
            store.commit( 'SET_PAGE_NUMBER', number )
        }

        onMounted( async () => {
           await getLogos()

        })
           
        return {  isActive, isActive2, onSubmit, setPageNumber, currentMediaQuery_, bodyRef_, }
    },
}
</script>
