<template>
<div class="promotions_slim  xl:px-10" data-aos="fade" >
    <carousel  :items-to-show="1" class="carousel_banners carousel_ver2" :autoplay="10000" :wrap-around="true" >
        <slide>            
            <div class="promotion_banner_card h-60 justify-between xs:flex xs:h-auto xs:text-center xs:bg-left xs:p-6 xs:min-h-0 p-7 xs:flex-col sm:flex sm:h-auto sm:text-center sm:bg-left sm:p-6 sm:min-h-0 sm:flex-col w-full rounded-xl bg-gray-200  grid grid-cols-6" >
                <div class="col-start-6 justify-center flex col-end-7 row-start-3 row-end-4 sm:float-right"><!--<img :src="slide.logo" class="xs:min-h-full xs:m-auto relative"/>--></div>
                <div class="col-start-1 col-end-4 row-start-2 row-end-4 float-right xs:text-center sm:text-center text-left">
                    <div class="text-white text-base xs:my-2 sm:text-base md:text-3xl lg:text-2xl xl:text-2xl 2xl:text-3xl font-semibold xs:text-md xs:font-semibold">
                        <Skeletor width="200px" /> 
                    </div>
                    <div class="text-white xs:hidden sm:hidden md:block lg::block xl:block 2xl:block sm:text-lg lg:text-base md:text-lg xl:text-base 2xl:text-lg mt-4 mb-4">
                        <Skeletor width="200px" /> 

                    </div>
                    <div class="text-black text-lg font-semibold sm:text-base md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl">
                        <Skeletor width="200px" /> 
                    </div>
                </div>
                <div class="xs:flex xs:justify-center sm:flex sm:justify-center col-start-6 col-end-7 row-start-2 row-end-3 xl:flex xl:justify-center lg:flex lg:justify-center">
                    <Skeletor width="200px" /> 
                </div>
            </div>
        </slide>
        <template #addons>
            <div class="pagination_container"><pagination class="black-bottom-pagination-container xs:left-0 xs:right-0 xs:bottom-3.5 sm:left-0 sm:right-0 sm:bottom-3.5"/></div>
        </template>
    </carousel>
</div>
</template>

<style scoped>
    .promotions_slim{
        @apply xs:p-6 sm:p-6  xs:mt-4 sm:mt-4 md:mt-4 mt-8 md:p-5 xs:pb-0 sm:pb-0 md:pb-0 mb-16 my-5 xs:my-0 xs:mx-0 md:mx-0 m-auto lg:container xl:container 2xl:container;
    }
    .promotion_banner_card {
        grid-template-rows: 10px 1fr 1fr 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 192px;
    }
    @media only screen and (min-width: 1681px)  {
       /* .promotion_banner_card {
            background-position-x:  -134px !important;
        } */
    }

    @media only screen and (min-width: 1601px) and (max-width: 1680px) {
       /* .promotion_banner_card {
            background-position-x:  -134px !important;
        } */
    }
     @media only screen and (min-width: 1367px) and (max-width: 1440px) {
        /*.promotion_banner_card {
            background-position-x: -207px !important;
        }*/
    }
    @media only screen and (min-width: 1281px) and (max-width: 1366px) {
        .promotion_banner_card {
            background-position-x: -140px !important;
        } 
    }

    @media only screen and (min-width: 1025px) and (max-width: 1280px) {
        .promotion_banner_card {
            background-position-x: -150px !important;
        } 
    }

    @media only screen and (min-width: 992px) and (max-width: 1024px)  {
       .promotion_banner_card {
            background-position-x: -280px !important;
        } 
    }
    @media only screen and (max-width:991px) {
        .carousel_ver2 .carousel__pagination{
            display: none;
        }
    }
    @media only screen and (max-width:480px) {
        .promotion_banner_card {
            height:255px;
        }
    }
</style>
<script>
import { Skeletor } from 'vue-skeletor';

export default {
    components: { Skeletor },
}
</script>


