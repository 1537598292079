<template>
	<div class=" w-full flex flex-col bg-white pb-2">
		<div
			class="px-3.5 xs:p-0"
			v-touch:swipe.left="swipeLeft"
			v-touch:swipe.right="swipeRight"
		>
			<div
				:style="` ${maxHeight}`"
				class="relative flex justify-center duration-300 ease-in-out "
			>
				<template v-for="(card, index) in list" :key="index">
					<card
						:index="index"
						:is-featured="( card.title == 1 ? true : false )"
						:is-mobile="isMobile"
						
					>
						<template v-slot:content>
							<card-layout :details="card" :is-mobile="isMobile"></card-layout>
						</template>
					</card>
				</template>
			</div>
		</div>
		<div
			v-if="buttons.list.length"
			class="flex justify-center items-center my-10 xs:mt-6 sm:mt-6 xs:mb-0 sm:mb-0 w-full gap-2 transform scale-90 "
		>
			<nav-buttons
				:list="buttons.list"
				:dataList="list"
				:is-mobile="isMobile"
				@move-card="moveCards"
			></nav-buttons>
		</div>
	</div>
</template>

<script>
import Card from "@/components/carousel/promotions_carousel/Card.vue";
import CardLayout from "@/components/carousel/promotions_carousel/CardLayout.vue";
import NavButtons from "@/components/carousel/promotions_carousel/NavButtons.vue";

const clampNumber = (num, a, b) =>
	Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

export default {
	name: "CarouselMobile",
	components: {
		Card,
		NavButtons,
		CardLayout,
	},
	props: {
		list: Array,
		cardWidth: {
			min: Number,
			max: Number,
		},
		height: Number,
		isMobile: Boolean,
	},
	mounted() {
		this.checkViewport();
		this.init();
		this.$nextTick(() => {
			if (this.cards.list.length > 0) {
				this.cards.list[0].select();
				this.cards.list[0].moveCard();
				if (Object.entries(this.buttons.selected).length > 0)
					this.buttons.selected.select();
			}
		});

		window.addEventListener("resize", this.checkViewport);
	},
	data() {
		return {
			cards: {
				selected: Object,
				list: [],
				featuredCount: 0,
			},
			xVal: 0,
			buttons: {
				selected: Object,
				list: [],
			},
			navButtons: Object,
			prevIndex: 0,
			viewport: 1440,
		};
	},
	computed: {
		maxHeight() {
			if(this.isMobile)
				return `height: 417px;`;
			else
				return `height: ${this.height}rem;`;
		},
		lastIndex() {
			return this.cards.list.length - 1;
		},
		isLastCard() {
			return Object.entries(this.buttons.selected).length > 0
				? this.buttons.selected.index === this.lastIndex
				: false;
		},
	},
	methods: {
		init() {
			this.cards.list.forEach((item, index) => {
				this.buttons.list.push(index);
			});
		},
		addCard(card) {
			this.cards.list.push(card);
		},
		select(card) {
			if (Object.entries(this.cards.selected).length > 0) {
				this.cards.selected.deselect();
				this.cards.selected.hideCard();
			}

			this.cards.selected = card;
		},
		checkViewport() {
			this.viewport = window.visualViewport.width;
		},
		swipeLeft() {
			this.nextCard(this.cards.selected.index + 1);
		},
		swipeRight() {
			this.nextCard(this.cards.selected.index - 1);
		},
		nextCard(index) {
			const totalCards = this.cards.list.length - 1;
			if (totalCards > 0) {
				index = clampNumber(index, 0, totalCards);

				const payload = {
					index,
					btn: this.navButtons.buttons[index],
				};
				this.moveCards(payload);
			}
		},
		moveCards(payload) {
			const { index, btn } = payload;

			if (
				Object.entries(this.buttons.selected).length > 0 &&
				this.buttons.selected !== btn
			) {
				this.prevIndex = this.buttons.selected.index;

				this.buttons.selected.deselect();
			}

			this.cards.list[index].select();
			this.cards.list[index].moveCard();
			btn.select();
			this.buttons.selected = btn;
		},
	},
};
</script>