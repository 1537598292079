
<template>
<div class="row">
    <PackageBannerLoader v-if="loading" />
    <div data-aos="fade" class="dish_packages md:container lg:container xl:container 2xl:container mx-auto xs:p-6 xs:pb-9 sm:pb-9 md:pb-9 xs:pt-8 md:p-5 md:bg-gray-150 xs:bg-gray-150 md:pt-7 sm:pt-1 sm:pl-5 sm:pr-5 xs:mt-2 sm:mt-2" v-if="!loading">
        <carousel :settings="settings" :itemsToScroll="1" :breakpoints="breakpoints" class="carousel_dish "  :class="{ 'is-center' : data.length < 3 }" v-if="data">
            <slide v-for="slide in data.list" :key="slide" :wrap-around="true">
                <div class="flex flex-col w-full lg:mx-2 xl:mx-2 2xl:mx-2 mb-10 xs:mb-8 sm:mb-8 md:w-3/6 mt-12 pt-10 rounded-2xl dish relative items-stretch justify-between pb-4" :class="{ 'bg-gray-100' : slide.group.is_featured != 'SI' , 'bg-red' : slide.group.is_featured == 'SI'}">                
                    <div>
                        <div v-if="slide.group.is_featured == 'SI' && slide.group.text_features != '' " 
                            class="bg-black text-white text-lg font-semibold absolute 
                                    w-full p-2 rounded-tr-2xl rounded-tl-2xl -top-7">
                            {{ slide.group.text_features }}
                        </div>  
                        <div v-if="slide.group.is_featured != 'SI'"
                            class="bg-red text-white absolute 
                                    -top-0 w-full p-2 rounded-tr-2xl rounded-tl-2xl"> </div>     
                        <div>
                            <h2 class="text-4xl font-semibold xs:mb-3 sm:mb-3 px-6"
                                :class="{ 'text-black' : slide.group.is_featured != 'SI', 
                                        'text-white' : slide.group.is_featured == 'SI', }"> {{ slide.serialize.nombre_paq  }}</h2>
                            <h3 class="text-6xl font-semibold xs:text-5xl xs:mb-2 sm:mb-2" 
                                :class="{ 'text-red2' : slide.group.is_featured != 'SI', 
                                        'text-white' : slide.group.is_featured == 'SI', }"> ${{ slide.package.price }} <span class="text-2xl">/mes</span></h3>
                            <span class="font-light"
                                :class="{ 'text-black' : slide.group.is_featured != 'SI', 
                                        'text-white' : slide.group.is_featured == 'SI',}" v-if="slide.serialize.descrip_paq" v-html="slide.serialize.descrip_paq"></span>   
                            <span class="font-light xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible"
                                :class="{ 'text-black' : slide.group.is_featured != 'SI', 
                                        'text-white' : slide.group.is_featured == 'SI',}" v-else v-html="`<div style='height:24px'></div>`"></span>           
                        </div>
                        <div class="w-10/12 mx-auto my-4"
                            :class="{ 'bg-black' : slide.group.is_featured != 'SI', 
                                    'bg-white' : slide.group.is_featured == 'SI', } " style="height:1px" />
                        <div class="flex xs:flex-col">
                            <div class="canales_container xs:w-9/12 sm:w-3/12 flex py-5 justify-center items-center text-lg xs:justify-end xs:pr-14 font-semibold rounded-r-full px-7 2xl:px-9 lg:p-1 leading-6"
                                :class="{ 'bg-black text-white ' : slide.group.is_featured != 'SI', 
                                        'bg-white' : slide.group.is_featured == 'SI' }">
                            {{ slide.serialize.num_can }} 
                            </div>
                            <div class="px-3 pt-2  pr-6 text-left xs:text-center text-sm xs:px-5 flex-1 flex-col flex justify-center">  
                                <p class="text-sm xs:text-base sm:text-base xs:my-3 sm:my-3" :class="{ 'text-black ' : slide.group.is_featured != 'SI',   
                                            'text-white' : slide.group.is_featured == 'SI' }">{{ slide.serialize.descrip_cnl }}</p>
                                <a href="#" class="block font-light semibold underline text-xl"
                                :class="{ 'text-red3 ' : slide.group.is_featured != 'SI', 
                                            'text-white' : slide.group.is_featured == 'SI' }" @click.prevent="isShow = true; isChannels = true ; disableScroll(false); productData = slide "> ver canales </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p  class="font-semibold text-xl xs:hidden" 
                                :class="{ 'text-black ' : slide.group.is_featured != 'SI', 
                                        'text-white' : slide.group.is_featured == 'SI' }"> {{ slide.serialize.titulo_paq }} </p>
                            <ul class="flex flex-wrap items-center mx-auto justify-center xs:hidden">
                                <li v-for="item in slide.serialize.repeat_detalles" :key="item" v-show="!isItemEmpty(item)"
                                    class="w-5/12 items-center bg-white  mx-2 flex rounded-lg dish-item-includes " >
                                    <img :src="item.image" :alt="item.text" v-if="!isArray(item.image)" class="mx-auto">
                                    <img :src="item.image" :alt="item.text" v-if="isArray(item.image)" class="mx-auto">
                                    <a href="#" v-if="item.note" class="mx-auto"> {{ item.note }} </a>

                                </li>
                            </ul>
                            <p  class="font-semibold text-xl mt-4 xs:mt-0" :class="{ 'text-black ' : slide.group.is_featured != 'SI',  
                                        'text-white' : slide.group.is_featured == 'SI' }" v-if="slide.serialize.titulo_destc.length > 0" v-html="slide.serialize.titulo_destc"> </p>
                            <p  class="font-semibold text-xl mt-4 xs:mt-0 xs:hidden sm:hidden md:visible lg:visible xl:visible 2xl:visible" :class="{ 'text-black ' : slide.group.is_featured != 'SI',  
                                        'text-white' : slide.group.is_featured == 'SI' }" v-else v-html="`<div style='height:28px'></div>`"></p>
                            <img :src="slide.serialize.img_destc" alt="" class="mx-auto " v-if="slide.serialize.img_destc">
                            <!-- <img src="@/assets/images/logo_freedom.svg" alt="" class="mx-auto " v-if="slide.group.is_featured == 'SI'"> -->
                            <a href="" class="semibold underline text-xl" :class="{'text-white' : slide.group.is_featured == 'SI', 'text-red3' : slide.group.is_featured != 'SI' }" @click.prevent="isShow = true; isChannels = false; productData = slide ; disableScroll(false)"> ver + info </a>
                        </div> 
                    </div>
                    <div>
                        <div class="bottom-5 w-full lg:bottom-2 xs:flex-col xs:justify-center xs:flex xs:w-max xs:m-auto pb-6 mt-4">
                            <a href="#" class="m-1 xs:p-3"
                                :class="{'btn-red' : slide.group.is_featured != 'SI',  'btn-light' : slide.group.is_featured == 'SI', } " @click="onSubmit(slide);"> Contratar </a> 
                        </div>
                    </div> 
                    <div v-html="tagImage"> </div>
                </div>
                
            </slide>
            <template #addons="{ slidesCount }">
            <navigation class="xs:hidden sm:hidden" v-if="slidesCount > 3"/>
            <pagination class="lg:hidden xl:hidden 2xl:hidden"/>
            </template>
        </carousel>
        
        <div class="legal_copy2 xs:p-0 sm:p-0 xs:mt-2 sm:mt-2 text-center" v-html="data.legal"> </div>
        <ModalPersonalize v-if="isShow" :isChannels="isChannels" @onClose="onClose" @setChannels="setChannels" :productData="productData" @onSubmit="onSubmit" />
    </div>
    <transition name="fade"> 
        <ModalMap v-if="isShowMap" :onShow="isShowMap" @onClose="onCloseMap"  @onSubmit="onSubmit" :productData="productData" :ref="data.title"/>
    </transition>
</div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import { mapState, useStore } from 'vuex'
import { inject } from '@vue/runtime-core'
import ModalPersonalize from '@/components/modals/ModalPersonalize'
import PackageBannerLoader from "@/components/loaders/Packages.vue"
import ModalMap from '@/components/modals/ModalMap.vue'
import { packageButton, coburtura } from '@/pixel/pixel.js'

export default {
    name: 'PackageCarousel',
    props: ['data'],
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        ModalPersonalize,
        PackageBannerLoader,
        ModalMap
    },
    computed:{
        ...mapState({
            loading : state =>  state.Home.loading
        })
    },
    setup(props) {
        const textLegal = ref("")
        const isShowMap = ref(false)
        const isShow = ref(false)
        const store = useStore()
        const productData = ref({})
        const isChannels = ref( false )
        const tagImage = ref('')
        const settings = {
            itemsToShow: 1,
            snapAlign: 'start',
            transition: 600
        }
        const breakpoints =  {
            // 700px and up
            700: {
                itemsToShow: 1,
                snapAlign: 'start',
            },
            // 1024 and up
            1024: {
                itemsToShow: 3,
                snapAlign: 'start',
                mouseDrag: false
            },
        }
        const currentMediaQuery_ = inject('mq')
        const currentMediaQuery = currentMediaQuery_.current
        const router =  useRouter()
        const setDatalayerAddtoCart = ( product ) => {
            /* eslint-disable-next-line */
            dataLayer.push({
                'event': 'add_to_cart',
                'ecommerce': {
                    'currencyCode': 'MXN',
                    'add': {                                
                        'products': [{            
                            quantity: 1,
                            name : product.package.name,
                            price: product.package.price,
                            id : product.package.erp_id,
                        }]
                    }
                }
            });

            packageButton.tag()
            const image =  packageButton.image+`<img src="${packageButton.postback+product.package.erp_id}"/> `
            tagImage.value = image
        }
        const onSubmit =( product ) =>{
            const scrollPos = document.documentElement.scrollTop
            store.commit('SET_SCROLL_POSITION', scrollPos)
            if( product.serialize.check_cover ){

                coburtura.tag()
                coburtura.floodlight()
                tagImage.value = coburtura.image

                if ( product.validate ){
                    setDatalayerAddtoCart( product )
                    store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                    router.push('/registro')
                } else {
                    productData.value = product
                    isShowMap.value = true
                }
            } else {
                setDatalayerAddtoCart( product )
                store.dispatch('setProduct', { ...product.package, ...product.serialize  })
                router.push('/registro')
            }      
        }
        const setTextLegal= ( text ) => {
            textLegal.value = text 
        }
        const onClose = () => {
            isShow.value = false    
            store.commit('SET_LOGOS', [])
            store.commit('SET_SCROLLABLE',true)
        }
        const setChannels = ( data ) => {
            isChannels.value = data
        }
        const isArray = ( data ) => {
            return ( Array.isArray(data) ? true : false )
        }
        const onCloseMap= ( ) => {
            isShowMap.value = false
            store.commit('SET_SCROLLABLE', true)
        }
        const disableScroll = (boolean) => {  
            store.commit('SET_SCROLLABLE', {boolean, currentMediaQuery})
            const scrollPos = document.documentElement.scrollTop
            store.commit('SET_SCROLL_POSITION', scrollPos)
        }
        const isItemEmpty = ( obj ) => {

            let temp = ""
            Object.keys(obj).forEach( (el) => {
                temp =   obj[el]
            });
            if( temp ){
               temp =  temp[0].trim()
            }
            return temp == "" ? true : false
        }
        const setProductDataLayer = () => {
            let products = []
            props.data.list.forEach( key => {
                products.push({
                    name : key.package.name,
                    price: key.package.price,
                    id : key.package.erp_id,
                })
            })

            return products
        }
        const setDatalayer = ( ) => {
            const products = setProductDataLayer()
            /* eslint-disable-next-line */
            dataLayer.push({
                'event' : 'view_item_list',
                'ecommerce' : {
                    'detail' : {
                        'actionField': {'list': props.data.title },
                        'products' : products
                    }
                }
            })
        }
        setDatalayer()

        return { 
            breakpoints,
            settings,
            isShowMap,
            isShow, 
            isChannels,
            currentMediaQuery,
            productData,
            textLegal,
            tagImage,
            isItemEmpty,
            isArray,
            setChannels,
            onClose,
            disableScroll,
            onSubmit,
            onCloseMap,
            setTextLegal }
    }
}
</script>
<style>
    @media only screen and (min-width:992px) {
        .dish_packages {
            max-width: 1187px !important;
        }
    }
    .carousel_dish li button{
        background-color: #c4c4c4 !important;
        width: 23px;
        height: 16px;
        border-radius: 20px;
    }
    .carousel_dish li button.carousel__pagination-button--active {
        background-color: #D6212E !important;
        width:80px
    }
    .carousel_dish.is-center .carousel__track{
         @apply justify-center;
    }
    .carousel_dish .carousel__track {
        @apply justify-start;
    }
    .carousel_dish .carousel__track {
        @apply justify-start;
    }
    
    .carousel_dish .carousel__prev, .carousel_dish .carousel__next {
        @apply bg-gray-350 text-white;
        width:70px;
        height:70px;
        border-radius: 50%;
    }
    .carousel_dish .carousel__prev:hover, .carousel_dish .carousel__next:hover {
        background:#000 !important
    }
    @media only screen and (min-width: 1440px)  {
        .carousel_dish .carousel__prev {
        transform: translateX(-90px);
        -o-transform: translateX(-90px);
        -ms-transform: translateX(-90px);
        -webkit-transform: translateX(-90px);
        -ms-transform: translateX(-90px);
        }
        .carousel_dish .carousel__next {
            transform: translateX(90px);
            -o-transform: translateX(90px);
            -ms-transform: translateX(90px);
            -webkit-transform: translateX(90px);
            -ms-transform: translateX(90px);
        }
    }
    .carousel_dish .carousel__icon {
        transform: scale(2.5);
    }
    
</style>
<style scoped>
    .legal_copy2 {
        font-size:11px; 
        padding:0px 10px
    }
    .dish-item-includes{
        height: 50px;
        margin-top: 10px;
        padding:7px
    }
    .dish-item-includes img {
        height: 100% !important;
    }
    .dish {
        height: 92%;
        box-shadow:0px 3px 11px rgba(0, 0, 0, 0.15)
    }
    .canales_container {
        max-height: 90px;
        max-width: 120px;
    }
    
    @media only screen and (min-width:992px) {
        .carousel_ver2 .carousel__pagination{
            display: none;
        }
        
    }
    @media only screen and (max-width:991px) {
        .canales_container {
            max-width: unset;
        }
    }
    @media only screen and (max-width:480px) {
        .promotion_banner_card {
            min-height: 65vh;
        }
        .carousel__pagination {
            margin-top:0px !important;
        }
    }    
</style> 