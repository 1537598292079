<template>
<div v-if="loading">
    <PromotionsBanner />
</div>
<div class="mx-auto xs:p-6 md:p-0 xs:pb-4 mb-5 xs:mb-0" data-aos="fade" v-if="!loading">
    <template v-if="isMobile">
			<carousel-mobile
				v-if="data"
                :list="data.list"
                :card-width="cardWidth"
                :height="height"
				:is-mobile="isMobile">
			</carousel-mobile>
	</template>
    <template v-else>
        <carousel 
            v-if="data"
            :list="data.list"
            :card-width="cardWidth"
            :height="height"
            :is-mobile="isMobile">
        </carousel>
    </template>
</div>
</template>

<style scoped>
    .carousel__slide {
        width:20% 
    }
    @media only screen and (max-width:480px) {
        .promotion_banner_card {
            min-height: 65vh;
        }
    }
    @media only screen and (min-width:1024px) {
        .promotion_banner_card {
            height:60vh
        }
    }
</style>

<script>
import 'vue3-carousel/dist/carousel.css';
import Carousel from "@/components/carousel/promotions_carousel/Carousel.vue";
import CarouselMobile from "@/components/carousel/promotions_carousel/CarouselMobile.vue";
import PromotionsBanner from "@/components/loaders/PromotionsBanner.vue"
import { mapGetters, mapState } from 'vuex'

const isMobile = () =>
	/iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(
		navigator.userAgent.toLowerCase()
	);

export default {
    name: 'PromotionBanner',
    props: ['data'],
    components: {
        Carousel,
        CarouselMobile, 
        PromotionsBanner
    },
    created() {
		if (isMobile()) {
			const viewport = window.visualViewport.width;
			if (viewport < 768) {
				this.cardWidth.min = viewport;
				this.cardWidth.max = viewport;
				this.isMobile = true;
			}
		}
	},
    computed:{
        ...mapGetters({
            promotionsTitle : 'getHomePromotionsTitle'
        }),
        ...mapState({
            loading : state => state.Home.loading 
        }),
    },
    data() {
		return {
			cardWidth: {
				min: 283,
				max: 588,
			},
			height: 36,
            isMobile: false,
		};
	},
}
</script>
