import { environment } from "./environment"
const env = environment.ENV
export const endpoints = {
    token: {
        getPublicToken :{
            method: 'GET',
            endpoint: ( env === 'development' || env === 'local'  ? 'core/get-public-token' : 'core/get-public-token'),
            params: { },
            documentUrl: ''
        },
    },
    iframe: {
        getFranky :{
            method: 'GET',
            endpoint: ( env === 'development' || env === 'local'  ? 'franky.json' : 'core/get-public-token'),
            params: { },
            documentUrl: ''
        }
    },
    page: {
        getPage :{
            method: 'GET',
            endpoint: '/pages/front/4',
            params: { },
            documentUrl: ''
        },
        getPageInfo : {
            method: 'GET',
            endpoint: '/pages/front/',
            params: { },
            documentUrl: ''
        },
        getPreviewModule :{
            method: 'GET',
            endpoint: '/contents/front/temporal-preview/',
            params: { },
            documentUrl: ''
        }
    },
    footer : {
        getFooter: {
            method: 'GET',
            endpoint: ( env === 'development' || env === 'local' ? 'footer.json' : 'footer/front/get-public-footer'),
            params: { },
            documentUrl: ''
        },
    },
    navbar :{
        getNavbar: {
            method: 'GET',
            endpoint: ( env === 'development' || env === 'local'  ? 'navbar.json' :  'navbar/front/get-public-tree-navbar' ), // ''),
            params: { },
            documentUrl: ''
        },
    },
    home:{
        getHomeData:{
            endpoint: ( env === 'development' || env === 'local'  ? 'home-response.json' : 'pages/front/'),
            params: { },
            documentUrl: ''
        },
        getPackageData:{
            endpoint: ( env === 'development' || env === 'local'  ? 'packages.json' : 'pages/front/4'),
            params: { },
            documentUrl: ''
        },
    },
    packages: {
        getPackageInformation: {
            endpoint: '/package/front/get-information-package',
            params: { },
            documentUrl: ''
        },
        getOnCoverage:{
            endpoint: "dishservices/front/cover/montalvo",
            document : 'https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929'
        },
        getLogos: {
            endpoint: "dishservices/front/packages/get-logos",
            document: "https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929"
        },
        getDataSuscription: {
            endpoint: "package/front/data-suscription",
            document: "https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929"
        }
    },
    search: {
        getPackagesChannel:{
            endpoint: "package/front/get-packages-of-channels/",
            document: "https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929"
        },
        getCategoriesChannel:{
            endpoint: "package/front/get-categories-channels",
            document: "https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929"
        },

        getAutocomplete:{
            endpoint: "package/front/autocomplete-channels/",
            document: "https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929"
        }
    },
    checkout :{
        subscribeEffectivo:{
            endpoint: 'dishservices/front/hiring/suscription-efective',
            params: { "telefono" : "string" },
            documentUrl: 'https://docs.google.com/spreadsheets/d/1K7XFHQ5NpK2zu-ABUo4HbPfELNTd3sffIdfm_5A8nYo/edit#gid=890174677'  
        },
        sendSMS:{
            endpoint: 'dishservices/front/sms/sendsms',
            params: { "telefono" : "string" },
            documentUrl: 'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        validateSMS : {
            endpoint : 'dishservices/front/sms/validationsms',
            params : { "codigo" : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        validateCustomer : {
            endpoint : 'dishservices/front/costumer/validation-costumer',
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        registerSubscriber: {
            endpoint : 'dishservices/front/hiring/register-subscriber',
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        registerAddress: {
            endpoint : 'dishservices/front/hiring/register-address',
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        registerProduct: {
            endpoint : 'dishservices/front/hiring/register-product',
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        registerMethod:{
            endpoint : 'dishservices/front/hiring/register-method',
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        sendStatusShipping : {
            endpoint :  "dishservices/front/hiring/status-hiring",
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        sendShipping : {
            endpoint :  "dishservices/front/hiring/send-hiring",
            params : { "telefono" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        autoCompletePostalCode : {
            endpoint : 'dishservices/front/geo/autocomplete-postal-code',
            params : { "postal_code" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        geoStatesCities:{
            endpoint : 'dishservices/front/geo/statescities',
            params : { "postal_code" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        geoDetailPostalCode: {
            endpoint : 'dishservices/front/geo/detail-postal-code',
            params : { "postal_code" : "string" , "coreo"  : "string"},
            document :  'https://docs.google.com/document/d/1dmGAw9nIU78g_Qg43NAeU0F0dudnxohl/edit#'
        },
        sendReferencePayment:{
            endpoint: "dishservices/front/sms/send-reference-payment",
            document : 'https://drive.google.com/file/d/1iv5z65SwbADuMvnyPC8C5IzJSXA_2bfT/view'
        },
        generateSignaturePay:{
            endpoint: "dishservices/front/payment/generate-signature-pay",
            document : 'https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929'
        },
        generateBarcode: {
            // endpoint: "dishservices/front/qr/generate-qr/",
            endpoint: "dishservices/front/others/reference-pay",
            document : 'https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929'
        },
        validateTelmex:{
            endpoint: "dishservices/front/telmex/validate-number",
            document : 'https://docs.google.com/spreadsheets/d/1TgFa7vkUDbIzxsuR_6eGgFfVsSsRwx5-xey6FZyTjs4/edit#gid=1796211929'
        },

    }
}