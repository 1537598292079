<template>
  <div class="p-5">
    <h3 class="uppercase text-2xl">MÉTODO DE PAGO</h3>
    <div class="separator_form"></div>
    <div v-html="tagImage"></div>
    <div class="step">
      <PagoLoader v-show="loading"> </PagoLoader>
      <div v-show="!loading">
        <p class="font-semibold text-18 mb-5 sm:text-center xs:text-center">
          Elige tu Método de Pago
        </p>
        <div></div>

        <Form @submit="onSubmit">
          <div class="my-3">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'paypal' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="paypal"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium"> Pay Pal</label>
            </div>
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                pt-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
                text-center
              "
              v-if="paymentMethod == 'paypal'"
            >
              <!-- <button
                  type="submit"
                  class="
                    btn btn-red
                    my-5
                    xs:w-8/12
                    sm:w-8/12
                    xs:self-center
                    sm:self-center
                  "
                >
                  Pagar ${{ parseFloat(parseInt(information.suscripcion)) }}
                </button>-->

              <div
                id="smart-button-container"
                class="xs:w-full md:w-full sm:w-full w-8/12"
              >
                <div style="text-align: center">
                  <div id="paypal-button-container"></div>
                </div>
              </div>
            </div>
          </div>
          
        </Form>

        <Form v-slot="{ errors }" @submit="onSubmit">
          <!-- v-show="selectedPackage.check_tdb" //va en la 38 -->
          <div class="my-3">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'card' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="card"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Tarjeta de Crédito</label
              >
            </div>
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                pt-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
                text-center
              "
              v-if="paymentMethod == 'card'"
            >
              <div class="w-full">
                <Field
                  name="card"
                  type="text"
                  v-maska="'#### - #### - #### - ####'"
                  :rules="isRequiredCard"
                  class="
                    tracking-widest
                    text-center
                    bg-white
                    font-gray-450
                    p-4
                    my-3
                    rounded-full
                    w-full
                  "
                  placeholder="1234-1234-1234-1234"
                />
              </div>
              <span class="text-red">{{ errors.carddb }}</span>

              <Field
                name="cardName"
                type="text"
                v-model="cardName"
                v-on:keypress="isLetter"
                :rules="isRequiredLettersOnly"
                pattern="[a-zA-Z]*"
                class="
                  tracking-widest
                  text-center
                  bg-white
                  font-gray-450
                  p-4
                  my-3
                  rounded-full
                  w-full
                "
                placeholder="Juan Jesus Martinez"
              />
              <span class="text-red">{{ errors.cardName }}</span>

              <p class="my-3">
                Al dar clic en <span class="font-bold"> “Pagar” </span> serás
                remitido a un portal seguro para realizar tu pago. Una vez
                realizado el pago, regresarás a nuestro portal con la
                confirmación de tu contratación.
              </p>
              <div class="xs:flex-col xs:flex sm:flex-col sm:flex">
                <button
                  type="submit"
                  class="
                    btn btn-red
                    my-5
                    xs:w-8/12
                    sm:w-8/12
                    xs:self-center
                    sm:self-center
                  "
                >
                  Pagar ${{ parseFloat(parseInt(information.suscripcion)) }}
                </button>
              </div>
            </div>
          </div>
        </Form>
        <Form v-slot="{ errors }" @submit="onSubmit"
          ><!-- v-show="showEffectivo" --><!-- v-show="selectedPackage.check_rpef && showEffectivo" -->
          <div class="my-3 text-center">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'efectivo' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="efectivo"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Pago en Efectivo
              </label>
              <span>{{ errors.field }}</span>
            </div>
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pt-6
                pl-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
              "
              v-if="paymentMethod == 'efectivo'"
            >
              <p class="text-sm">
                Paga en cualquiera de nuestros lugares de pago presentando el
                siguiente código de barras:
              </p>
              <img :src="barcode.qr_code" alt="barcode" class="mx-auto my-5" />
              <p class="text-center text-lg font-bold">
                {{ barcode.referencia }}
              </p>
              <p class="text-center underline mt-4 text-xs">
                Una vez realizado el pago tu paquete quedara habilitado
              </p>
              <div class="text-center">
                <button class="btn btn-red my-5 mx-auto" type="submit">
                  Enviar a mi celular
                </button>
              </div>
              <p class="my-3 text-sm mb-0">
                Estos son algunos de los lugares donde puedes realizar tu pago:
              </p>
              <div class="flex mt-5 justify-center">
                <div class="w-3/12">
                  <img src="@/assets/images/stores/bbva.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/chedraui.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/farmacias_ahorro.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="flex mt-5 justify-center">
                <div class="w-3/12">
                  <img src="@/assets/images/stores/hsbc.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img src="@/assets/images/stores/oxxo.png" alt="" srcset="" />
                </div>
                <div class="w-3/12">
                  <img
                    src="@/assets/images/stores/walmart.png"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <p class="text-center underline mt-4 mb-3">
                <a
                  href="https://dish.com.mx/soporte-y-servicio/lugares-de-pago-dish"
                  target="_blank"
                  >Consulta aquí todos los lugares de pago</a
                >
              </p>
            </div>
          </div>
        </Form>
        <Form v-slot="{ errors }" @submit="onSubmit"
          ><!-- v-show="selectedPackage.check_rtl" -->
          <div class="my-3 text-center">
            <div
              class="
                bg-gray-450
                p-4
                md:w-full
                pl-6
                rounded-full
                w-8/12
                xs:w-full
                sm:w-full
                lg:w-10/12
                flex
                items-center
              "
              :class="{ active: paymentMethod == 'telmex' }"
            >
              <Field
                name="paymentMethod"
                type="radio"
                value="telmex"
                class="mr-3"
                v-model="paymentMethod"
              />
              <label for="paymentMethod" class="font-medium">
                Recibo telefónico Telmex
              </label>
              <span>{{ errors.paymentMethod }}</span>
            </div>
            <div
              class="
                bg-gray-450
                md:w-full
                p-4
                pt-6
                pl-6
                rounded-br-3xl rounded-bl-3xl
                transition-all
              "
              v-if="paymentMethod == 'telmex'"
            >
              <p class="text-sm">
                Ingresa el número telefónico al que está registrado tu
                <span class="font-bold"> Recibo Telmex </span> para pagar
                mensualmente.
              </p>
              <p class="underline mt-4">Número Telefónico (10 dígitos)*</p>
              <Field
                name="telmex"
                type="text"
                v-maska="'#### - #### - ##'"
                class="
                  bg-white
                  font-gray-900
                  xs:w-full
                  sm:w-full
                  p-4
                  my-3
                  rounded-full
                  w-8/12
                "
                aria-placeholder="0000 - 0000 - 00"
                placeholder="0000 - 0000 - 00"
                :rules="isRequiredTenDigits"
              />
              <div>{{ errors.telmex }}</div>
              <div class="xs:flex xs:flex-col">
                <button
                  type="submit"
                  class="
                    btn btn-red
                    my-5
                    xs:self-center
                    sm:self-center
                    xs:w-8/12
                    sm:w-8/12
                  "
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <!-- https://www.procom.prosa.com.mx/eMerch2/7480527_dish.jsp -->
      <form
        method="POST"
        action="https://www.procom.prosa.com.mx/eMerchant/7480527_dish.jsp"
        id="formProsa"
      >
        <input type="hidden" name="merchant" :value="signatureData.merchant" />
        <input type="hidden" name="store" :value="signatureData.store" />
        <input type="hidden" name="term" :value="signatureData.term" />
        <input type="hidden" name="total" :value="signatureData.total" />
        <input type="hidden" name="currency" :value="signatureData.currency" />
        <input type="hidden" name="order_id" :value="signatureData.orderId" />
        <input type="hidden" name="digest" :value="signatureData.digest" />
        <input type="hidden" name="urlBack" :value="signatureData.url" />
      </form>
      <transition name="fade">
        <ModalNotificationInvalidPostalCode
          v-show="error"
          :message="error"
          @onClose="onCloseSubscriptionError"
        />
      </transition>
      <transition name="fade">
        <ModalPaymentEfectivo
          v-if="isSuccessPaymentEffectivo"
          :barcode="barcode"
          :barCodeReference="barCodeReference"
          @onClose="onCloseModalEfectivo"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import { maska } from "maska";
import { Form, Field } from "vee-validate";
import { mapState, mapGetters, useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import axios, { AxiosResponse } from "axios";
import PagoLoader from "@/components/loaders/Pago.vue";
import ModalNotificationInvalidPostalCode from "@/components/modals/ModalNotificationInvalidPostalCode.vue";
import ModalPaymentEfectivo from "@/components/modals/ModalPaymentEfectivo.vue";
import { pagoEffectivo, pagar, checkoutStepSix } from "@/pixel/pixel.js";
import { baseURL, environment } from "../../api/environment";
import { watch } from "@vue/runtime-core";
export default {
  directives: { maska },
  computed: {
    ...mapGetters({
      error: "getError",
    }),
    ...mapState({
      loading: (state) => state.Subscription.loading,
      information: (state) => state.Products.information,
      barcode: (state) => state.Subscription.barcode,
      selectedPackage: (state) => state.Products.selected,
    }),
  },
  components: {
    Field,
    Form,
    PagoLoader,
    ModalNotificationInvalidPostalCode,
    ModalPaymentEfectivo,
  },
  methods: {},

  setup() {
    const cardName = ref("");
    const store = useStore();
    const router = useRouter();
    const paymentMethod = ref("");
    const signatureData = ref({});
    const ccRecurring = ref(true);
    const isSuccessPaymentEffectivo = ref(false);
    const barCodeReference = ref({ dataObj: { sms: null } });
    const product = store.state.Products.selected;
    const showEffectivo = ref(true);
    const tagImage = ref("");
    const env = environment.ENV;

    // viejo
    let ambiente = env == "production" ? "prod" : "stg";
    let key =
      env == "production"
        ? "YIJIu9f3xigXhlO7HFhQD6GVmgNhgj"
        : "6HRiEZ9esNWIATJfRG0fXWJ7uGIYmI";
    let client =
      env == "production"
        ? "VCM-DISH8844575-MXN-CLIENT"
        : "VCM-DISH-STG-MXN-CLIENT";

    // nuevo
    // let ambiente = env == "production" ? "prod" : "prod";
    // let key =
    //   env == "production"
    //     ? "YIJIu9f3xigXhlO7HFhQD6GVmgNhgj"
    //     : "YIJIu9f3xigXhlO7HFhQD6GVmgNhgj";
    // let client =
    //   env == "production"
    //     ? "VCM-DISH8844575-MXN-CLIENT"
    //     : "VCM-DISH8844575-MXN-CLIENT";

    const isRequiredCard = (value) => {
      if (value) {
        if (value.replace(/ /g, "").replace(/-/g, "").length > 0) {
          if (value.replace(/ /g, "").replace(/-/g, "").length < 15) {
            return "Tu número debe tener 15 - 16 números.";
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return "Este campo no lo puedes dejar en blanco.";
      }
    };
    const isRequired = (value) => {
      return value ? true : "Este campo no lo puedes dejar en blanco.";
    };
    // -- INIT ---
    const rutaDev = '';
    // -- END ---
    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      rutaDev +
      "/resumen";
    const isRequiredLettersOnly = (value) => {
      if (/\d/.test(value)) {
        return "Tu nombre no puede contener números.";
        /* eslint-disable-next-line */
      } else if (/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
        return "Tu nombre no puede contener personajes especiales.";
      } else {
        return value ? true : "Este campo no lo puedes dejar en blanco.";
      }
    };
    const isRequiredTenDigits = (value) => {
      if (value) {
        if (value.replace(/ /g, "").replace(/-/g, "").length < 0) {
          if (value.replace(/ /g, "").replace(/-/g, "").length < 10) {
            return "Tu número debe tener 10 números.";
          }
        }
        return true;
      } else {
        return value ? true : "Tu número debe tener 10 números.";
      }
    };
    const onCloseSubscriptionError = () => {
      store.commit("SET_ERROR_SUBSCRIPTION", "");
    };

    // pay pal
    const initPayPalButton = () => {
      console.log("Inicia paypal dkf", paymentMethod);

// suscripcion TC
//       const { suscripcion } = store.state.Products.information;


          //Datos de efectivo
      // console.log("efectivoprice",store.state.Subscription.suscripcion_efectivo);

      const  suscripcion  = store.state.Subscription.suscripcion_efectivo;
      console.log("susc:", suscripcion);

// await store.dispatch("saveIdPaypal", orderData.id);
       store.dispatch("saveSuscEfectivo", suscripcion);

      // const { folio } = store.state.Subscription.subscriber_registration.dataObj;
      // console.log("folio 1:", folio);



      setTimeout(() => {
        const { folio } =
          store.state.Subscription.subscriber_registration.dataObj;
        console.log("folio c. paypal:", folio);

        paypal.Buttons({
            style: {
              shape: "pill",
              color: "gold",
              layout: "horizontal",
              label: "paypal",
              tagline: false,
            },

            createOrder: function (data, actions) {
              return actions.order.create({
                // purchase_units: [
                //   { amount: { currency_code: "MXN", value: suscripcion } },
                // ],

                // const idpaypal = store.state.Subscription.id_paypal;
                // console.log("idpaypal 3:", idpaypal);

                purchase_units: [
                  {
                    reference_id: folio,
                    invoice_id: folio,
                    custom_id: folio,
                    amount: {
                      currency_code: "MXN",
                      value: suscripcion,
                      breakdown: {
                        item_total: {
                          currency_code: "MXN",
                          value: suscripcion,
                        },
                      },
                    },
                    items: [
                      {
                        name: "Servicios DISH DIGITAL",
                        quantity: "1",
                        category: "DIGITAL_GOODS",
                        unit_amount: {
                          currency_code: "MXN",
                          value: suscripcion,
                        },
                      },
                    ],
                  },
                ],
              });
            },

            onApprove: async function (data, actions) {
              //Mandando el servicio de OrderId
              //               {
              //         "folio": "B0022923",
              //         "monto": "134"
              //                }
              console.log("folio s gerry:", folio);
              console.log("monto s gerry:", suscripcion);

              let peticion = {
                folio: folio,
                monto: suscripcion,
              };

              console.log("req enviado", peticion);
              let respuesta = await store.dispatch("tranOrderId", peticion);
              console.log(respuesta);

              return actions.order.capture().then(async function (orderData) {
                // Full available details
                console.log(
                  "Capture result",
                  orderData,
                  JSON.stringify(orderData, null, 2)
                );

                console.log("id de la orden paypal", orderData.id);
                // console.log("folio 2:", folio);
                //Guardo el order ID
                await store.dispatch("saveIdPaypal", orderData.id);

                onSubmit();

                // Inserta Transacción Paypal_EcoDigital
                // { "folio": "B0022923",
                //  "monto": 134,
                //   "orderIdDish": "PP1210220240280",
                //   "orderIdPaypal": "72K94208L0564592C",
                //   "estatus": "COMPLETED",
                //   "request": "{\"orderID\":\"72K94208L0564592C\",\"payerID\":\"UUJ8GAD45VU2Y\",\"paymentID\":null,\"billingToken\":null,\"facilitatorAccessToken\":\"A21AAI6Se2anbe35zHK7W4R6cGPil1-mV61gEZVm5We0Jo5_bUQFAsVm3kn8zxag-IdPQFkqOyfgpUxNCoAJ7iC3ViXSZraDg\",\"paymentSource\":\"paypal\"}",
                //    "response": "{\"orderID\":\"72K94208L0564592C\",\"payerID\":\"UUJ8GAD45VU2Y\",\"paymentID\":null,\"billingToken\":null,\"facilitatorAccessToken\":\"A21AAI6Se2anbe35zHK7W4R6cGPil1-mV61gEZVm5We0Jo5_bUQFAsVm3kn8zxag-IdPQFkqOyfgpUxNCoAJ7iC3ViXSZraDg\",\"paymentSource\":\"paypal\"}",
                //    "canalPago": "1" }

                // valores chidos
                // console.log("Los datos a insertar en segundo servicio son:");
                // console.log("folio:", folio);
                // console.log("monto:", suscripcion);
                // console.log("orderIdDish:", respuesta.orderid);
                // console.log("orderIdPaypal:", orderData.id);
                // console.log("estatus:", orderData.status);
                 console.log("request:", orderData.purchase_units);
                 let a=JSON.stringify(orderData.purchase_units);
                console.log("a",a);

                 
                 console.log("response:", orderData);
                 let b=JSON.stringify(orderData);
                console.log("b",b);

                 
              
                // console.log("canalPago:", 1);

                let pet = {
                  folio: String(folio),
                  monto: String(suscripcion),
                  orderIdDish: String(respuesta.orderid),
                  orderIdPaypal: String(orderData.id),
                  estatus: String(orderData.status),
                  // request: Request.toString(orderData.purchase_units),
                  request: a,
                  response: b,
                  // request: String(orderData.status),
                  // response: String(orderData.status),
                  canalPago: String(1)
                };

                let resp = await store.dispatch("tran_paypal", pet);
                console.log("tranpaypal:", resp);

                // Show a success message within this page, e.g.
                const element = document.getElementById(
                  "paypal-button-container"
                );
                element.innerHTML = "";
                element.innerHTML =
                  "<p>En un lapso de 10 días un técnico asistirá a tu domicilio para instalar/activar tu(s) servicio(s), de acuerdo con lo que hayas contratado.</p>";

                // Or go to another URL:  actions.redirect('thank_you.html');
              });
            },

            onError: function (err) {
              console.log(err);
              const element = document.getElementById(
                "paypal-button-container"
              );
              element.innerHTML = "";
              element.innerHTML =
                "<p>Ocurrió un error con el pago, favor de validar.</p>";
            },
          })
          .render("#paypal-button-container");
      }, 100);
    };

    const onSubmit = async (data) => {
      let error = store.getters.getError;

      pagar.tag();
      pagar.floodlight();
      tagImage.value = pagar.image;
      //debo obtener que tipo de tarjeta es:
      // console.log("data", data.card);

      if (paymentMethod.value == "card") {
        // await store.dispatch("generateSignaturePay");
        let resp = await store.dispatch("setRegisterMethod", data);
        console.log("serv setRegisterMethod", resp);
        console.log("validcaion", resp.dataObj.codigo_respuesta);

        if (
          paymentMethod.value == "card" &&
          resp.dataObj.codigo_respuesta != 222
        ) {
          console.log("Se llama card TC");

          const firstDigit = data.card.substring(0, 1);

          var cardType;
          console.log("data", firstDigit);
          switch (firstDigit) {
            case "3":
              cardType = "AMEX";
              break;
            case "4":
              cardType = "VISA";
              break;
            case "5":
              cardType = "Master Card";
              break;
            case "6":
              cardType = "DISCOVERY";
              break;
          }
          console.log(cardType);

          console.log("data", data);

          // await store.dispatch("generateSignaturePay");
          let resp = await store.dispatch("setRegisterMethod", data);
          console.log("serv setRegisterMethod", resp);

          //se manda llamar pagar test
          const { suscripcion, descripcion } = store.state.Products.information;
          const { telefono } = store.state.Users.user_contact;
          const { email } = store.state.Users.user_additional_contact;
          const { folio } =
            store.state.Subscription.subscriber_registration.dataObj;
          const { firstName } = store.state.Users.user.firstName;
          const { lastName } = store.state.Users.user.lastName;
          const { middleName } = store.state.Users.user.middleName;
          console.log("folio:", folio);
          // console.log("sus:", suscripcion);

          // Open Checkout with further options:
          paymentCheckout.open({
            user_id: folio, // ?
            user_email: email, // Opcional  email
            user_phone: telefono, // Opcional telefono
            order_description: descripcion, //conceptos productos o servicios
            order_amount: Number(suscripcion), //total a pagar 1
            order_vat: 0,
            order_reference: "#234323411", //order id numero de orden
            //order_installments_type: 2, // Opcional: 0 para permitir cuotas, -1 en caso contrario.
            conf_exclusive_types: "vi,mc", // Opcional: Tipos de tarjeta permitidos para esta operación. Opciones: https://developers.gpvicomm.com/api/#metodos-de-pago-tarjetas-marcas-de-tarjetas
            conf_invalid_card_type_message:
              "Tarjeta invalida para esta operación", // Opcional: Define un mensaje personalizado para mostrar para los tipos de tarjeta no válidos.
          });

          // Cerrar el Checkout en la navegación de la página:
          window.addEventListener("popstate", function () {
            paymentCheckout.close();
          });

          //Se manda a llamar el primer servicio
          // const end_point = 'https://8yqrjf6yq2.execute-api.us-east-1.amazonaws.com/stage/appdishfp/preciosim';
          // const result = await axios.get(end_point);
          // console.log("obtengo data de service 1",result.data)

          // let end_point = 'https://481vwlnrk7.execute-api.us-east-1.amazonaws.com/dev/tranEnviadaVicomm' ;
          let request = {
            cliente: folio,
            orderId: folio,
            canalPago: 137,
            correo: email,
            telefono: telefono,
            nombre:
              store.state.Users.user.firstName +
              " " +
              store.state.Users.user.lastName +
              " " +
              store.state.Users.user.middleName,
            monto: Number(suscripcion),
            canalOrigen: 1,
            idConcepto: "",
          };
          console.log("req enviado", request);
          let respuesta = await store.dispatch("tranEnviadaVicomm", request);
          console.log(respuesta);
        } else if (
          paymentMethod.value == "card" &&
          resp.dataObj.codigo_respuesta == 222
        ) {
          console.log("Se llama card DB");

          const firstDigit = data.card.substring(0, 1);

          var cardType;
          console.log("data", firstDigit);
          switch (firstDigit) {
            case "3":
              cardType = "AMEX";
              break;
            case "4":
              cardType = "VISA";
              break;
            case "5":
              cardType = "Master Card";
              break;
            case "6":
              cardType = "DISCOVERY";
              break;
          }
          console.log(cardType);

          await store.dispatch("generateSignaturePay");
          await store.dispatch("setRegisterMethod", data);
        } else if (paymentMethod.value == "carddb") {
          await store.dispatch("generateSignaturePay");
          await store.dispatch("setRegisterMethod", data);
        }
      }

      // pay pal
      else if (paymentMethod.value == "paypal") {
        // console.log("Log paypal");

        await store.dispatch("setRegisterMethod", {
          paymentMethod: "paypal",
        });

        await store.dispatch("sendStatusShipping");
        await store.dispatch("sendShipping");

        // const { folio } = store.state.Subscription.subscriber_registration.dataObj;
        // console.log("folio 3:", folio);
        // const idpaypal = store.state.Subscription.id_paypal;
        // console.log("idpaypal 3:", idpaypal);

        router.push("/resumen");
      } else if (paymentMethod.value == "telmex") {
        await store.dispatch("validateTelmex", data);
        error = await store.getters.getError;

        if (error) {
          return;
        }

        await store.dispatch("setRegisterMethod", data);
        error = await store.getters.getError;

        if (!error) {
          router.push("/resumen");
        }
      } else {

        console.log("ingreso a pago en efe")
        // console.log("Aqui");
        await store.dispatch("setRegisterMethod", {
          paymentMethod: "efectivo",
        });
        // await store.dispatch("sendStatusShipping");
        await store.dispatch("sendShipping");

        // await store.dispatch("setRegisterMethod", data);
        await store.dispatch("sendReferencePayment");

        error = await store.getters.getError;

        if (!error) {
          barCodeReference.value = await store.state.Subscription
            .barcode_sms_reference;
          isSuccessPaymentEffectivo.value = true;
        }
      }
    };

    const isLetter = (e) => {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z\s]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault();
    };

    const onCloseModalEfectivo = () => {
      isSuccessPaymentEffectivo.value = false;
    };

    const setDatalayer = () => {
      /* eslint-disable-next-line */
      dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
          detail: {
            products: {
              name: product.name,
              price: product.price,
              id: product.erp_id,
            },
          },
        },
      });

      checkoutStepSix.tag();
      checkoutStepSix.floodlight();
      tagImage.value = checkoutStepSix.image;
    };

    const checkEffectivo = async () => {
      // console.log("check efectivo");
      const x = await store.dispatch("getSuscriptionEfective");
      console.log(store.state.Subscription.suscripcion_efectivo);
      showEffectivo.value = !x ? false : true;
    };

    const setEfectivoControls = async () => {
      // console.log("efectivo controls");

      pagoEffectivo.tag();
      pagoEffectivo.floodlight();
      tagImage.value = pagoEffectivo.image;
      if (Object.keys(store.state.Subscription.register_method) == 0) {
        await store.dispatch("generateBarcode");
        await store.dispatch("getSuscriptionEfective");
        // await store.dispatch("setRegisterMethod", {
        //   paymentMethod: "efectivo",
        // });
        // await store.dispatch("sendStatusShipping");
        // await store.dispatch("sendShipping");
      }
    };

    let paymentCheckout = new PaymentCheckout.modal({
      client_app_code: client, // Application Code de las credenciales CLIENT
      client_app_key: key, // Application Key de las credenciales CLIENT
      locale: "es", // Idioma preferido del usuario (es, en, pt). El inglés se usará por defecto
      env_mode: ambiente, // `prod`, `stg`, `local` para cambiar de ambiente. Por defecto es `stg`
      onOpen: function () {
        console.log("Modal abierto pago");
        console.log("AMB FUNCION", ambiente);
      },
      onClose: function () {
        console.log("Modal cerrado pago");
      },

      onResponse: async function (response) {
        // Funcionalidad a invocar cuando se completa el proceso de pago
        console.log("Response pago", response);

        if (response.transaction.status == "success") {
          console.log("Paso chido :)");

          const { suscripcion, descripcion } = store.state.Products.information;
          const { folio } =
            store.state.Subscription.subscriber_registration.dataObj;

          let request = {
            cliente: folio,
            orderId: folio,
            canalPago: "137",
            currentStatus: response.transaction.current_status,
            authCode: response.transaction.authorization_code,
            monto: Number(suscripcion),
            reference: response.transaction.id,
            productDesc: descripcion,
            canalOrigen: 1,
            idConcepto: "",
          };

          console.log("req enviado 2", request);

          let respuesta = await store.dispatch("tranEnviadaVicomm2", request);
          console.log(respuesta);

          // manda a resumen
          router.push("/resumen");
        } else {
          console.log("No paso :( pago");
          // x si no lo hacen
          await console.log("error: ", response.transaction.carrier_code);

          var Error;
          switch (response.transaction.carrier_code) {
            case "0":
              Error = "Esperando para ser Pagada";
              break;
            case "1":
              Error =
                "Se requiere verificación, por favor revise la sección de Verificar";
              break;
            case "3":
              Error = "Pagada";
              break;
            case "6":
              Error = "Fraude";
              break;
            case "7":
              Error = "Reverso";
              break;
            case "8":
              Error = "Contracargo";
              break;
            case "9":
              Error = "Rechazada por el procesador";
              break;
            case "10":
              Error = "Error en el sistema";
              break;
            case "11":
              Error = "Fraude detectado por Global Payments ViComm";
              break;
            case "12":
              Error = "Blacklist de Global Payments ViComm";
              break;
            case "13":
              Error = "Tiempo de tolerancia";
              break;
            case "14":
              Error = "Expirada por Global Payments ViComm";
              break;
            case "15":
              Error = "Expirado por el carrier";
              break;
            case "19":
              Error = "Código de autorización invalido";
              break;
            case "20":
              Error = "Código de autorización expirado";
              break;
            case "21":
              Error = "Fraude Global Payments ViComm - Reverso pendiente";
              break;
            case "22":
              Error = "AuthCode Inválido - Reverso pendiente";
              break;
            case "23":
              Error = "AuthCode Expirado - Reverso pendiente";
              break;
            case "24":
              Error = "Fraude Global Payments ViComm - Reverso solicitado";
              break;
            case "25":
              Error = "AuthCode Inválido - Reverso solicitado";
              break;
            case "26":
              Error = "AuthCode Expirado - Reverso solicitado";
              break;
            case "27":
              Error = "Comercio - Reverso pendiente";
              break;
            case "28":
              Error = "Comercio - Reverso solicitado";
              break;
            case "29":
              Error = "Anulada";
              break;
            case "31":
              Error = "Esperando OTP";
              break;
            case "32":
              Error = "OTP validado correctamente";
              break;
            case "33":
              Error = "OTP no validado";
              break;
            case "34":
              Error = "Reverso parcial";
              break;
            case "35":
              Error = "Método 3DS solicitado, esperando para continuar";
              break;
            case "36":
              Error = "Desafío 3DS solicitado, esperando el CRES";
              break;
            case "37":
              Error = "Rechazada por 3DS";
              break;
            case "":
              Error =
                "Se produjo un error con la transacción en el banco. Favor de reintentar.";
              break;
            case null:
              Error =
                "Se produjo un error con la transacción en el banco. Favor de reintentar.";
              break;
            case undefined:
              Error =
                "Se produjo un error con la transacción en el banco. Favor de reintentar.";
              break;
          }
          console.log(Error);

          window.alert(Error);
        }

        console.log("Respuesta de modal pago");
        // document.getElementById('response').innerHTML = JSON.stringify(response);
      },
    });

    watch(paymentMethod, async (val) => {
      store.commit("SET_PAYMENT_METHOD", val);
      if (val == "efectivo") {
        setEfectivoControls();
      } else if (val === "paypal") {

        await checkEffectivo();
        await initPayPalButton();
        
    
      }
      //   return val
    });

    checkEffectivo();
    setDatalayer();

    // pay pal
    // initPayPalButton();

    return {
      tagImage,
      cardName,
      onSubmit,
      showEffectivo,
      ccRecurring,
      isRequired,
      isRequiredLettersOnly,
      isRequiredTenDigits,
      isSuccessPaymentEffectivo,
      barCodeReference,
      paymentMethod,
      onCloseSubscriptionError,
      signatureData,
      window,
      isLetter,
      onCloseModalEfectivo,
      isRequiredCard,
      // pay pal
      initPayPalButton,
    };
  },
};
</script>
<style scoped>
.active {
  @apply rounded-br-none rounded-bl-none bg-red text-white rounded-tr-2xl  rounded-tl-2xl w-full;
}
input[type="radio"] {
  @apply relative;
  width: 20px;
  height: 20px;
  cursor: pointer !important;
}
h3,
.separator_form {
  max-width: 440px;
}
input[type="radio"]:checked:after {
  @apply left-0 top-0 bg-black rounded-full relative visible border-white border-solid inline-block border-2;
  width: 20px;
  height: 20px;
  content: "";
}
.separator {
  @apply w-full border-solid border border-gray-300;
}
.pago_cards {
  gap: 10px;
}
.pago_cards input {
  flex-basis: 32%;
}
@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .pago_cards input {
    flex-basis: 31%;
  }
}
@media screen and (max-width: 480px) {
  .pago_cards input {
    flex-basis: 48%;
  }
  .separator_form {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .pago_cards input {
    flex-basis: 48%;
  }
  .separator_form {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

/* #paypal-button-container{
  height: 80px !important;
  background-color: blue ;

}

#smart-button-container{
  height: 80px !important;
  background-color: red ;

} */

.paypal-button-row {
  height: 10px !important;
}
</style>
