<template>
  <div class="home">
    <Header />
      <SearchComponent />   
    <Footer /> 
  </div>
</template>
<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SearchComponent from '@/components/Search.vue'

export default {
  name: 'SearchHome',
  components: {
    Header,
    Footer,
    SearchComponent,
  },
}
</script>