<template>
    <ul class="flex justify-center" v-if="loading">
        <Skeletor width="100px" v-for="index in 4" :key="index" class="mr-3 my-10" />
    </ul>
     <ul class="flex justify-center items-center  w-full visible xs:hidden sm:hidden md:hidden lg:visible nav h-24" v-if="!loading">
        <li class="p-3 font-medium hover:text-red text-lg flex relative " 
            v-for="( { childs, title, destination_route,  destination_type , resource_information} , index) in navbar" :key="index">
            
             <a :href="destination_route" :alt="title"  @click.prevent="setPageNumber(destination_route, resource_information, destination_type)" v-if="destination_type == 'I'" class="flex text-base w-max"  target="_self" >
                {{title}}
                <img src="@/assets/images/icon-arrow-down-dark.svg" class="ml-3" :alt="'navigation-child-'+title" title="nav-icon" v-if="childs.length != 0" >
            </a>

            <a :href="destination_route" target="_blank"  class="flex text-base w-max"  v-if="destination_type == 'E'"> 
                {{title}}
                <img src="@/assets/images/icon-arrow-down-dark.svg" class="ml-3" :alt="'navigation-child-'+title" title="nav-icon" v-if="childs.length != 0" >
            </a>
            <div class="child-container flex flex-col invisible bg-pink absolute z-10 nav top-10  mt-2 w-max" v-if="childs.legnth != 0">
                <ul class="child px-2">
                    <li class="p-3 flex " v-for="({ title, destination_route, destination_type, icon_url, resource_information} , index) in childs" :key="index" >
                        <div class="flex flex-col justify-center">
                            <div>
                                <img :src="icon_url" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-if="icon_url" >
                                <img src="@/assets/images/submenu_icon.png" class="mr-1" :alt="'navigation-child-'+title" title="nav-icon" v-else>
                            </div>
                        </div>
                        <a :href="destination_route" :alt="title"  @click.prevent="setPageNumber(destination_route, resource_information, destination_type)" v-if="destination_type == 'I'" class="flex text-base w-max"  target="_self" >
                            {{title}}
                        </a>
                        <a :href="destination_route" :alt="title"  target="_blank"  class="flex text-base w-max"  v-if="destination_type == 'E'"> 
                            {{title}}
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</template>
<style scoped>
    ul.nav li  {
       /* display: block; */
        cursor: pointer;
    }
    ul.nav li:hover *,
    .child-container:hover{
       visibility: visible;
    }
    ul.nav li:last-child .child-container {
        right:0px;
    }
    .child-container {
        box-shadow:0px 8px 11px rgba(0,0,0,0.5);
        min-width: 230px;
    }
    ul.child li{
        border-bottom: 1px solid #D6212E;
    }
    ul.child li:hover a{
        color:#9b222b 
    }
    ul.child li:last-child{
        border-bottom: none
    }
    ul.nav li:last-child .child-container {
        width:100%
    }
</style>
<script>
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';
import { mapGetters, mapState } from 'vuex'
import config from "../../vue.config"

export default {
    computed:{
        ...mapGetters({
            navbar: 'getNavbarList',
        }),
        ...mapState({
            loading :  state => state.Navbar.loading
        })
    },
    components:{
        Skeletor,
    },
    setup(){
        const setPageNumber =  ( pageNumber, resource_information, destination_type ) =>  {
            console.log( destination_type )
            location.href  = `${config.publicPath}${resource_information.slug}`
            window.scrollTo(0, 0);
        }
        return { setPageNumber }
    }
}
</script>