<template>
<div class="row">
    <PromotionsSlimLoader v-if="loading"/>
    <div class="xs:p-6 sm:p-6  xs:mt-4 sm:mt-4 md:mt-4 mt-10 md:p-5 xs:pb-0 sm:pb-0 md:pb-0 mb-16 my-5 xs:mb-6 sm:mb-6 xs:my-0 xs:mx-0 md:mx-0 m-auto lg:px-10 md:px-10  slim_banner" data-aos="fade"  v-if="!loading" >
        <carousel  :items-to-show="1" class="carousel_banners carousel_ver2"  :breakpoints="breakpoints" v-if="data">
            <slide v-for="slide in data.list" :key="slide" :wrap-around="true">            
                <div class="promotion_banner_card h-60 justify-between xs:flex xs:h-auto xs:text-center xs:bg-left xs:p-6 xs:min-h-0 p-7 xs:flex-col sm:flex sm:h-auto sm:text-center sm:bg-left sm:p-6 sm:min-h-0 sm:flex-col w-full rounded-xl bg-black grid grid-cols-6" :style="{ background:`url(${ getImagePerResoluition(slide.image_descktop, slide.image_mobile, currentMediaQuery)} `, backgroundRepeat: 'no-repeat', backgroundSize: 'cover',  backgroundPosition: 'left center'}">
                    <div class="col-start-6 justify-center flex col-end-7 row-start-3 row-end-4 sm:float-right"><!--<img :src="slide.logo" class="xs:min-h-full xs:m-auto relative"/>--></div>
                    <div class="col-start-1 col-end-4 row-start-2 row-end-4 float-right xs:text-center sm:text-center text-left">
                        <div class="text-white leading-8 xs:leading-6 sm:leading-6 xs:my-2 text-28 font-semibold xs:text-lg xs:font-semibold">{{ slide.title }}</div>
                        <div class="text-white xs:hidden xs:leading-6 sm:leading-6 sm:hidden md:block lg:block xl:block 2xl:block text-18 mt-1 mb-1">{{ slide.text_secondary }}</div>
                        <div class="text-black xs:leading-6 sm:leading-6 font-semibold text-26">{{ slide.text_price }}</div>
                    </div>
                    <div class="xs:flex xs:justify-center sm:flex sm:justify-center col-start-6 col-end-7 row-start-2 row-end-3 lg:flex lg:justify-end lg:flex-col xl:flex xl:justify-end xl:flex-col 2xl:flex 2xl:justify-end 2xl:flex-col" v-if="slide.type_redirect">
                        <a class="btn-red m-0 px-6 w-56 lg:px-3 xl:px-3 cursor-pointer mr-2 "  target="_blank" rel="noreferrer" @click.prevent="setPageNumber(slide.type_redirect)" v-if="slide.type_redirect.split('|')[0] == 'I' && slide.text_call_to_action != null">  {{ slide.text_call_to_action }} </a> 
                        <a class="btn-red m-0 px-6 w-56 lg:px-3 xl:px-3  cursor-pointer" :href="slide.type_redirect.split('|')[2]" target="_blank"  v-if="slide.type_redirect.split('|')[0] == 'E' && slide.text_call_to_action != null" rel="noreferrer">  {{ slide.text_call_to_action }} </a>
                    </div>
                </div>
            </slide>
            <template #addons>
                <div class="pagination_container"><pagination class="black-bottom-pagination-container xs:left-0 xs:right-0 xs:bottom-3.5 sm:left-0 sm:right-0 sm:bottom-3.5"/></div>
            </template>
        </carousel>
    </div>
</div>
</template>

<style scoped>
    .promotion_banner_card {
        grid-template-rows: 10px 1fr 1fr 10px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 192px;
    }
    @media only screen and (min-width:1440px) {
        .slim_banner {
            max-width: 1440px   !important;
        }
    }
    @media only screen and (min-width:1280px) and (max-width:1365px) {
        .slim_banner {
            @apply px-10
        }
    }
    @media only screen and (max-width:991px) {
        .carousel_ver2 .carousel__pagination{
            display: none;
        }
    }
    @media only screen and (max-width:480px) {
        .promotion_banner_card {
            height:255px;
        }
    }
</style>


<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { mapState, useStore } from 'vuex'
import { inject } from '@vue/runtime-core';
import PromotionsSlimLoader from '@/components/loaders/PromotionsSlim.vue'
export default {
    name: 'PromotionSlim',
    props: ['data'],
    components: {
        Carousel,
        Slide,
        Pagination,
        PromotionsSlimLoader
    },
    computed:{
        ...mapState({
            loading : state => state.Home.loading
        })
    },
    setup(  ) {
        const store = useStore()
        const  breakpoints = {
            1024: {
                mouseDrag: false,
                transition: 600
            }
        }
        const currentMediaQuery = inject('mq')
        const setPageNumber = ( type_redirect ) => {
            console.log( type_redirect )
			const type = type_redirect.split('|')[0]
			const pageNumber = type_redirect.split('|')[1]
			const link = type_redirect.split('|')[2]

			if( type == 'I'){
				if( pageNumber ){
					store.commit( 'SET_PAGE_NUMBER', pageNumber )
                    window.scrollTo(0, 0);
				}
			} else {
				window.location.href = link;
			}
		}
        const getImagePerResoluition = ( desktopImage, mobileImage, mediaQuery ) => {
            return (mediaQuery.current == 'xs' || mediaQuery.current == 'sm' || mediaQuery.current == 'md' ?  encodeURI(mobileImage) : encodeURI(desktopImage)  )
        }
        return { breakpoints ,currentMediaQuery, getImagePerResoluition, setPageNumber}
    },
}
</script>