export const facebookPixelPageView = () =>{
    /*eslint-disable */
    fbq('track', 'PageView');
}

export const ampAnalytics = ( to ) => {
    let amp, script, scriptNode, body, ampEl;

    body = document.getElementsByTagName('body')[0]
    ampEl = document.getElementsByTagName('amp-analytics')[0]

    if( ampEl ) {
        body.removeChild( ampEl )
    }
    if( to.name == 'Resumen'){
        scriptNode = JSON.stringify(
            {
            "vars": { "gtag_id": "AW-10798387531", "config": { "AW-10798387531": { "groups": "default" } } },
            "triggers": { "C_qNE1CtEut3M": { "on": "visible", "vars": { "event_name": "conversion", "send_to": ["AW-10798387531/rVJZCImv74ADEMuiiZ0o"] } } }
            })
    } else {
        scriptNode = JSON.stringify({ "vars": { "gtag_id": "AW-10798387531", "config": { "AW-10798387531": { "groups": "default" } } }, "triggers": { } })
    }
    amp = document.createElement('amp-analytics')
    amp.setAttribute('type', 'gtag')
    amp.setAttribute('data-credentials', 'include')
    script = document.createElement('script')
    script.setAttribute('type', 'application/json')
    script.appendChild( document.createTextNode(scriptNode))
    
    amp.appendChild( script )
    document.body.appendChild(amp)
}