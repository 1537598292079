export const getSearchPackagesChannel = (state) => {
    let p = []
    if (state.packages.dataObj) {

        let packages = state.packages.dataObj
     
        try {
            packages.forEach(element => {
                // console.log(element.value )
                if (element.value != "")
                p.push({ ...element, package: element, serialize: JSON.parse(element.value) })
            });
            // console.log(p)
        }
        catch (e) {
            console.log(e)
        }

    }
    return p
}