import { createStore } from 'vuex'

import Home from '@/store/home'
import Navbar from '@/store/navbar'
import Footer from '@/store/footer'
import Token from '@/store/token'
import Subscription from '@/store/subscription'
import Franky from '@/store/franky'
import Products from '@/store/products'
import Users from '@/store/users'
import Pages from '@/store/pages'
import Preview from '@/store/preview'
import Search from '@/store/search'
export default createStore({
  modules: {
    Navbar,
    Footer,
    Home, 
    Token,
    Subscription,
    Franky,
    Products,
    Users, 
    Pages,
    Preview,
    Search
  }
})
